import { asArray } from '@app/lib';
import { includes, isNil } from 'lodash';

declare global {
    interface Window {
        _env: Record<string, any>;
    }
}

// Not sure if isDevelopment/isProduction are being used in laz, MODE doesn't appear to be getting set
const isDevelopment = getEnvironmentVariable('MODE') === 'development';
const isProduction = getEnvironmentVariable('MODE') === 'production';

const showGlobalError =
    isDevelopment && getEnvironmentVariable('VITE_SHOW_GLOBAL_ERROR') === 'true';
const appVersion = getEnvironmentVariable('VITE_VERSION') || 'dev';

const predefs = {
    isDevelopment,
    isProduction,
    showGlobalError,
    appVersion,
};

export default predefs;

export function getEnvironmentVariable(variableName: string) {
    if (!isNil(window?._env?.[variableName])) {
        return window?._env?.[variableName];
    }
    if (!isNil(import.meta.env[variableName])) {
        return import.meta.env[variableName];
    }
}

export function isAppVersion(version: string | string[], autoIncludeDev = true) {
    const versionArray = asArray(version);
    if (autoIncludeDev) {
        versionArray.push('dev');
    }
    return includes(versionArray, appVersion);
}
