import React from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { resources as i18Resources } from '@app/i18n';
import { resources as componentResources } from '@app/components';
import { resources as clientResources } from '../i18n';
import { merge } from 'lodash';

i18n.use(initReactI18next).init({
    resources: merge({}, componentResources, i18Resources, clientResources),
    lng: 'en',
});

export function I18nProvider(props: any) {
    return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>;
}

export function getTranslation(key: string) {
    return i18n.t(key);
}
