import { lazy } from 'react';

export type LoadRoute<P> = (props?: P) => Promise<{ default: React.ComponentType<P> }>;

export interface LazyRoute<P> extends React.LazyExoticComponent<React.ComponentType<P>> {
    preload: LoadRoute<P>;
}

/**
 * Equivalent to React.lazy but for LazyRoute
 *
 * @param loadRoute Same as React.lazy
 * @returns LazyRoute (A preloadable React.LazyExoticComponent)
 */
export function lazyPage<P>(loadRoute: LoadRoute<P>) {
    // @ts-expect-error Not sure if there is a better way to type this
    const Component: LazyRoute<P> = lazy(loadRoute);
    Component.preload = loadRoute;
    return Component;
}
