import React, { useCallback, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { compact, isArray, flatMap } from 'lodash';
import { useDebouncedEffect } from '@app/components';
import { components as ReactSelectComponents } from 'react-select';

//Need to do this as we are passing the "value" of the selected options into the onChange handlers.
//Doing this to make it easier to use on forms, so the model should be the value we want to send to the API rather than having to do any extra transformation
//We can't pass these values directly to react-select, instead need to find the actual options from the list and give it those
export const findMatchingOptions = (
    valueToUse,
    optionList,
    isMulti,
    getValueFn = (option) => option.value,
) => {
    //If the options in the list has its own "options" list then assume they're using groups, so we want to check the inner option list
    if (optionList?.[0]?.options) {
        const foundOptions = compact(
            flatMap(optionList, (option) =>
                findMatchingOptions(valueToUse, option.options, isMulti, getValueFn),
            ),
        );
        if (isMulti) {
            return foundOptions;
        } else if (foundOptions?.length) {
            //Not multi select so should only have one option
            return foundOptions[0];
        } else {
            return undefined;
        }
    }
    if (valueToUse !== null && valueToUse !== undefined) {
        if (isMulti) {
            //if multiple values can be selected
            return isArray(valueToUse)
                ? optionList?.filter(
                      (option) => !!valueToUse?.find((x) => getValueFn(option) === x),
                  )
                : [];
        } else {
            //only single value can be selected
            return optionList?.find((option) => getValueFn(option) === valueToUse);
        }
    }
};

export const getOptionsForValue = (value, options, isMulti, getValueFn) => {
    const matchingOption = findMatchingOptions(value, options, isMulti, getValueFn);
    const hasValue = isMulti ? !!matchingOption?.length : !!matchingOption;
    return hasValue ? matchingOption : value; //just return value if we can't currently match it to any options
};

//Function to transform given options into the desired output using the valueFn
export function transformSelectOptions(givenOptions, valueFn, isMulti) {
    if (givenOptions) {
        if (isMulti) {
            //if multiple values can be selected
            return givenOptions.map(valueFn);
        } else {
            //only single value can be selected
            return valueFn(givenOptions);
        }
    }
    return null;
}

export function getValueForOptions(givenOptions, isMulti, getValue = (option) => option.value) {
    return transformSelectOptions(givenOptions, getValue, isMulti);
}

export function getLabelForOptions(givenOptions, isMulti, getLabel = (option) => option.label) {
    return transformSelectOptions(givenOptions, getLabel, isMulti);
}

const Select = forwardRef(function Select(
    {
        value,
        defaultValue,
        options,
        getOptionLabel = (option) => option.label,
        getOptionValue = (option) => option.value,
        getOutputValue: givenOutValueFunc,
        onChange,
        isMulti,
        isAsync,
        allowCreate,
        validateOption,
        fixedOptions,
        components,
        ...rest
    },
    ref,
) {
    const valueToUse = value ?? defaultValue;

    const selectedOptions = useMemo(() => {
        return getOptionsForValue(valueToUse, options, isMulti, getOptionValue);
    }, [valueToUse, options, isMulti, getOptionValue]);

    //Default function to transform selected options into the desired output for passing to the onChange handler
    const getValueForSelectedOptions = useCallback(
        (selectedOptions) => {
            return transformSelectOptions(selectedOptions, getOptionValue, isMulti);
        },
        [getOptionValue, isMulti],
    );

    //Apparently ignoreAccents defaults to true and can cause performance issues for larger lists
    const filterOption = createFilter({
        ignoreAccents: false,
        matchFrom: 'any',
        stringify: (option) => `${option.label}`,
    });

    let SelectComponent = ReactSelect;
    if (allowCreate) {
        SelectComponent = CreatableSelect;
    } else if (isAsync) {
        SelectComponent = AsyncSelect;
    }

    const getOutputValue = useCallback(
        (values) => {
            const outputFunc = givenOutValueFunc ? givenOutValueFunc : getValueForSelectedOptions;
            return outputFunc(values);
        },
        [getValueForSelectedOptions, givenOutValueFunc],
    );

    //Watch for changes to fixed options - force fixed options to be included in the selectedOptions if not already
    //NOTE: For some reason this wasn't working without debouncing the effect? Possible race condition?

    const debouncedCallback = useCallback(() => {
        if (isMulti && !!fixedOptions?.length) {
            const missingFixedOptions = fixedOptions.filter(
                (option) =>
                    !selectedOptions?.find((selectedOption) => {
                        return getOptionValue(option) === getOptionValue(selectedOption);
                    }),
            );
            if (!!missingFixedOptions?.length) {
                onChange?.(getOutputValue([...(selectedOptions ?? []), ...missingFixedOptions]));
            }
        }
    }, [fixedOptions, getOptionValue, getOutputValue, isMulti, onChange, selectedOptions]);
    useDebouncedEffect(debouncedCallback, 0);

    const handleSelectChange = (selectedOptions, { action, removedValue }) => {
        let outValue = selectedOptions;
        switch (action) {
            case 'remove-value':
            case 'pop-value':
                if (isOptionFixed(removedValue)) {
                    return;
                }
                break;
            case 'clear':
                outValue = fixedOptions ?? (isMulti ? [] : null); //reset to only the fixed options
                break;
        }
        onChange?.(getOutputValue(outValue));
    };

    const isOptionFixed = (givenOption) => {
        return !!fixedOptions?.find(
            (option) => getOptionValue(option) === getOptionValue(givenOption),
        );
    };

    return (
        <SelectComponent
            value={selectedOptions}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={handleSelectChange}
            ref={ref}
            instanceId="glx-select"
            isMulti={isMulti}
            filterOption={filterOption}
            closeMenuOnSelect={!isMulti}
            components={{
                ...components,
                MultiValue: function MultiValue(props) {
                    return (
                        components?.MultiValue(props) || ( //use multi value if given via props, otherwise return our own custom one
                            <CustomMultiValue
                                {...props}
                                isDisabledFunc={isOptionFixed}
                                validateOption={validateOption}
                            />
                        )
                    );
                },
            }}
            {...rest}
        />
    );
});

Select.propTypes = {
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    options: PropTypes.array,
    isMulti: PropTypes.bool, //true if multiple options can be selected - means "value" will be an array
    getOptionValue: PropTypes.func, //Resolves option data to a string to be displayed as the label by components
    getOptionLabel: PropTypes.func, //Resolves option data to a string to compare options and specify value attributes
    getOutputValue: PropTypes.func, //function to transform the selected options to return to the onChange handler
    onChange: PropTypes.func,
    fixedOptions: PropTypes.array, // options that are always present / selected
    allowCreate: PropTypes.bool, //whether or not the user can create custom options
    isAsync: PropTypes.bool, //whether or not to use the Async component - NOTE: allowCreate takes priority
    components: PropTypes.object, //custom react select components
    validateOption: PropTypes.func, //Function to validate an option - used for when allowCreate is true and you want to check the custom values are valid
};

function CustomMultiValue({ validateOption, isDisabled, isDisabledFunc, ...props }) {
    props.isValid = !validateOption || !!validateOption(props.data); //add custom prop so we can use this to theme accordingly
    return (
        <ReactSelectComponents.MultiValue
            {...props}
            isDisabled={isDisabled || isDisabledFunc(props.data)}
        />
    );
}

CustomMultiValue.propTypes = {
    data: PropTypes.any,
    isDisabled: PropTypes.bool,
    isDisabledFunc: PropTypes.func,
    validateOption: PropTypes.func,
};

export { Select };
