import React from 'react';
import { SimpleFormControl, SimpleFormControlProps } from './SimpleFormControl';
import { isValidPhoneNumber } from 'libphonenumber-js';

type PhoneNumberFormControlProps = SimpleFormControlProps;

export function PhoneNumberFormControl({ rules, ...rest }: PhoneNumberFormControlProps) {
    const combinedRules = {
        validate: {
            validPhoneNumber: (value: string) =>
                isValidPhoneNumber(value) ||
                'Phone number must be of valid format including country code (e.g. +61)',
        },
        ...rules,
    };

    return <SimpleFormControl rules={combinedRules} {...rest} />;
}
