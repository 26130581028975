import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import {
    // eslint-disable-next-line no-restricted-imports
    Link as RouterLink,
    // eslint-disable-next-line no-restricted-imports
    LinkProps as RouterLinkProps,
    // eslint-disable-next-line no-restricted-imports
    NavLink as RouterNavLink,
    // eslint-disable-next-line no-restricted-imports
    NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import { usePreload } from './usePreload';

interface CustomLinkProps {
    /**
     * If the route should be preloaded
     *
     * Defaults to true which prefetches when on screen and on mouse over
     * false will only preload on mouse over
     */
    preload?: boolean;
}

interface IsNavLink<Bool extends boolean> {
    /**
     * Should it be a Link or NavLink
     */
    isNavLink?: Bool;
}

// Useful types for custom componenets
export type NavLinkProps = RouterNavLinkProps & CustomLinkProps;
export type LinkProps = RouterLinkProps & CustomLinkProps;

// The type of Link
export type BothLinkProps = (NavLinkProps & IsNavLink<true>) | (LinkProps & IsNavLink<false>);

/**
 * Only run callback when the browser is idle
 */
function requestOnIdle(callback: () => void) {
    // If not supported just queue as a task with setTimeout
    if (!requestIdleCallback) {
        setTimeout(() => callback(), 1);
    } else {
        // Will run callback when browser is idle
        requestIdleCallback(() => callback());
    }
}

export function Link({ preload = true, to, isNavLink = false, ...props }: BothLinkProps) {
    const ref = useRef<HTMLAnchorElement>(null);

    const { canPreload, handlePreload } = usePreload(to);

    // Preload when the link is within 200px of the screen or on screen
    useEffect(() => {
        if (canPreload && preload && ref?.current) {
            const observer = new IntersectionObserver(
                (entries) =>
                    entries.forEach((entry) => {
                        // Only preload if the browser is idle
                        entry.isIntersecting && requestOnIdle(handlePreload);
                    }),
                { rootMargin: '200px' },
            );

            observer.observe(ref.current);
            return () => observer.disconnect();
        }
    }, [canPreload, handlePreload, preload]);

    // Preload when you mouse over the link
    const onMouseEnter = useCallback(
        () => canPreload && handlePreload(),
        [canPreload, handlePreload],
    );

    const LinkComponent = useMemo(() => (isNavLink ? RouterNavLink : RouterLink), [isNavLink]);

    // @ts-expect-error Typing with dynamic component props is hard
    return <LinkComponent ref={ref} to={to} onMouseEnter={onMouseEnter} {...props} />;
}
