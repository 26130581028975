import React from 'react';
import { useClientConfig } from '../../utils/config';
import { Helmet } from 'react-helmet';

export const DocumentHead = () => {
    const config = useClientConfig();
    const { title, faviconPath } = config;
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="icon" type="image/svg+xml" href={faviconPath} />
        </Helmet>
    );
};
