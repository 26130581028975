import React from 'react';
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { IconType } from 'react-icons';
import { forwardRef, RightJoinProps } from '@chakra-ui/system';
import { Link, NavLinkProps } from '../../../utils/pages/Link';

const styleConfig = {
    baseStyle: () =>
        ({
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 'lg',
            paddingX: 4,
            paddingY: 4,
            textAlign: 'center',
            flex: 'none',
            fontSize: 'sm',
            alignItems: 'center',
            transitionProperty: 'colors',
            transitionDuration: 'slow',
            lineHeight: 'shorter',
            position: 'relative',
            _hover: {
                backgroundColor: 'primary.50',
            },
            _activeLink: {
                backgroundColor: 'primary.50',
                '.menu-icon': {
                    color: 'primary.500',
                },
            },
        } as SystemStyleObject),
};

export interface HamburgerMenuItemProps extends BoxProps {
    icon?: IconType;
    children?: React.ReactNode;
}

export const HamburgerMenuItem = forwardRef(function HamburgerMenuItem(
    { icon: Icon, children, ...rest }: HamburgerMenuItemProps,
    ref,
) {
    const styles = useStyleConfig('HamburgerMenuItem', { styleConfig });
    return (
        <Box sx={styles} data-testid="menu-item" ref={ref} {...rest}>
            {Icon && <Box as={Icon} className="menu-icon" size={24} marginRight={4} />}
            {children}
        </Box>
    );
});

export type HamburgerMenuNavLinkProps = RightJoinProps<NavLinkProps, HamburgerMenuItemProps>;

// Renders a sidebar item as a NavLink from react-router - https://reactrouter.com/web/api/NavLink
export const HamburgerMenuNavLink = forwardRef(function HamburgerMenuNavLink(
    { ...rest }: HamburgerMenuNavLinkProps,
    ref,
) {
    return <HamburgerMenuItem as={Link} isNavLink preload={false} ref={ref} {...rest} />;
});
