import { keycloak, refreshToken } from '../auth/keycloak';
import { getEnvironmentVariable } from '../env';
import { CloseCode, createClient as createWSClient } from 'graphql-ws';
import { maxWebsocketRetryCount, useWebsocketStatus } from './websocketStore';
import { includes } from 'lodash';
import { timeUntilExpiry } from '@app/lib';

const { getState } = useWebsocketStatus;

export const websocketUrl = getEnvironmentVariable('VITE_GRAPHQL_BASE_URL').replace(/http/i, 'ws');

let requiresNewToken = false;
let tokenExpiryTimeout: undefined | NodeJS.Timeout = undefined;

export const websocketClient = createWSClient({
    url: websocketUrl,
    keepAlive: 10000,
    connectionParams: async () => {
        if (requiresNewToken) {
            // refresh the token because it is no longer valid
            await refreshToken();
            // and reset the flag to avoid refreshing too many times
            requiresNewToken = false;
        }
        return { token: keycloak.token };
    },
    shouldRetry: (errOrCloseEvent) => true,
    retryAttempts: maxWebsocketRetryCount,
    // Hook into lifecycle events and keep track of the state in a store so we can more easily refer to the state elsewhere
    on: {
        connected: (socket: any) => {
            // clear timeout on every connect for debouncing the expiry
            if (tokenExpiryTimeout) {
                clearTimeout(tokenExpiryTimeout);
            }

            function onTokenExpire() {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.close(CloseCode.Forbidden, 'token invalid');
                }
            }

            // Set a token expiry timeout for closing the socket with an `4403: Forbidden` close event indicating that the token expired.
            // The `closed` event listner below will set the token refresh flag to true
            tokenExpiryTimeout = setTimeout(
                onTokenExpire,
                timeUntilExpiry(keycloak.tokenParsed?.exp),
            );
            getState().onConnected();
        },
        connecting: () => getState().onConnecting(),
        error: (error) => getState().onError(error),
        closed: (event: any) => {
            getState().onClosed(event);
            if (includes(event?.reason, 'token invalid')) {
                // Auth token expired?
                requiresNewToken = true;
            }
        },
    },
});
