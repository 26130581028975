import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ConfigContext, defaultCombinedConfig } from '../../utils/config';
import { useFetchConfigQuery } from '../../gql/generated/config';
import { merge } from 'lodash';
import store from 'store';
import { TimeSyncProvider } from './TimeSyncProvider';
import { isEqual } from 'lodash';

type Timer = ReturnType<typeof setInterval>;

export default function ConfigWrapper({
    authReady = false,
    children,
}: {
    authReady?: boolean;
    children: React.ReactNode;
}) {
    const [fetchConfig, setFetchConfig] = useState<any>();

    // Attempt to load the stored config from last session, this way we can use this as a more accurate default until the real response arrives
    const storedConfig = useMemo(() => {
        return store.get('glxConfig') ?? {};
    }, []);

    // Combine our base config with the config loaded from the backend (or fall back to a stored config if it exists)
    const mergedConfig = useMemo(() => {
        return merge({}, defaultCombinedConfig, fetchConfig ?? storedConfig);
    }, [fetchConfig, storedConfig]);

    // Persist the config to local storage so we can get a more accurate defaultConfig next time they visit the app
    useEffect(() => {
        store.set('glxConfig', mergedConfig);
    }, [mergedConfig]);

    return (
        <ConfigContext.Provider value={mergedConfig}>
            <ConfigUpdater authReady={authReady} config={fetchConfig} setConfig={setFetchConfig} />
            <TimeSyncProvider isReady={authReady}>{children}</TimeSyncProvider>
        </ConfigContext.Provider>
    );
}

function ConfigUpdater({
    authReady,
    config,
    setConfig,
}: {
    authReady?: boolean;
    config: any;
    setConfig: (config: any) => void;
}) {
    const [fetchedConfig, reexecute] = useFetchConfigQuery({
        pause: !authReady,
    });

    const interval = useRef<Timer | null>(null);
    useEffect(() => {
        if (interval.current !== null) {
            clearInterval(interval.current);
        }
        interval.current = setInterval(() => {
            reexecute();
        }, 60000);

        return () => {
            if (interval.current !== null) {
                clearInterval(interval.current);
            }
            interval.current = null;
        };
    }, [reexecute]);

    useEffect(() => {
        if (!isEqual(config, fetchedConfig.data?.fetchConfig)) {
            setConfig(fetchedConfig.data?.fetchConfig);
        }
    }, [config, fetchedConfig.data?.fetchConfig, setConfig]);

    return null;
}
