import React from 'react';
import { SimpleFormControl, SimpleFormControlProps } from './SimpleFormControl';
import { validate } from 'email-validator';

type EmailFormControlProps = SimpleFormControlProps;

export function EmailFormControl({ rules, ...rest }: EmailFormControlProps) {
    const combinedRules = {
        validate: {
            validEmail: (value: string) => validate(value) || 'Email format is invalid',
        },
        ...rules,
    };

    return <SimpleFormControl rules={combinedRules} {...rest} />;
}
