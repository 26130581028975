import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { getEnvironmentVariable } from './utils/env';
import { datadogRum } from '@datadog/browser-rum';
import './utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import { KeycloakManager } from './components/Keycloak/KeycloakManager';

if (getEnvironmentVariable('VITE_DATADOG_APP_ID')) {
    datadogRum.init({
        applicationId: getEnvironmentVariable('VITE_DATADOG_APP_ID'),
        clientToken: getEnvironmentVariable('VITE_DATADOG_CLIENT_TOKEN'),
        site: 'datadoghq.com',
        service: 'glx-lazarus',
        env: getEnvironmentVariable('VITE_DATADOG_APP_ENV'),
        version: getEnvironmentVariable('VITE_DATADOG_APP_VERSION'),
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [getEnvironmentVariable('VITE_GRAPHQL_BASE_URL')],
    });

    datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <KeycloakManager>
                <App />
            </KeycloakManager>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
