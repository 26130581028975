// NOTE: This type is based on the webpush shape in @app/server/src/types/notifme-template/index.d.ts

import { generatePath } from 'react-router-dom';
import { appPaths } from '../../utils/routeConfig';

// For now just declaring the parts of it that we need, in future we could possibly share the type with the server
type WebpushData = {
    entity: 'trade' | 'bid' | 'termsheetReview' | undefined;
    notificationData: Record<string, any>;
};

// Given a notification object (or specifically it's data)
export function getNotificationUrl(data: WebpushData): string | null {
    if (data.entity) {
        switch (data.entity) {
            case 'trade':
                return getTradeUrl(data);
            case 'bid':
                return getBidUrl(data);
            case 'termsheetReview':
                return getTermsheetReviewUrl(data);
        }
    }
    return null;
}

// Assuming notification is for a trade, generate the trades URL from the provided data
function getTradeUrl(data: WebpushData): string | null {
    const uid = data.notificationData?.tradeUid;
    if (uid) {
        return generatePath(appPaths.tradeDetails, { uid });
    }
    return null;
}

// Assuming notification is for a bid, generate the activities URL from the provided data
function getBidUrl(data: WebpushData): string | null {
    const uid = data.notificationData?.tradeUid;
    const companyId = data.notificationData?.tradeCompanyId;
    const activityId = data.notificationData?.activityId;
    if (uid && companyId && activityId) {
        return generatePath(appPaths.tradeTermsheet, {
            uid,
            companyId,
            activityId,
        });
    } else {
        getTradeUrl(data);
    }
    return null;
}

// Assuming notification is for a termsheet review, generate the review URL from the provided data
function getTermsheetReviewUrl(data: WebpushData): string | null {
    const id = data.notificationData?.termsheetReviewId;
    if (id) {
        return generatePath(appPaths.reviewsDetails, { id });
    }
    return null;
}
