import { useCallback, useMemo, useRef, useEffect } from 'react';
import {
    useSubscription as useUrqlSubscription,
    SubscriptionHandler as UrqlSubscriptionHandler,
    UseSubscriptionArgs as UrqlUseSubscriptionArgs,
    UseSubscriptionResponse,
} from 'urql';
import { useUpdatingRef } from '../../utils/hooks/useUpdatingRef';
import { usePollingFallback } from '../../utils/websocket/usePollingFallback';
import { useWebsocketStatus, websocketPollingInterval } from '../../utils/websocket/websocketStore';

export declare type UseSubscriptionArgs<Variables = object, Data = any> = UrqlUseSubscriptionArgs<
    Variables,
    Data
> & {
    /** Pass as true to prevent the automatic fallback to polling */
    noPollingFallback?: boolean;
    /** Override default polling interval - default = 10000 */
    pollingInterval?: number;
};

// Redefining subscription handler to allow sending a third parameter to indicate whether the handler was called due to a polling event (when websockets are not supported)
export declare type PollingResponse = {
    prevData: undefined;
    data: undefined;
    isPollingEvent: true;
};
export declare type StandardResponse<T, R> = {
    prevData: R | undefined;
    data: T;
    isPollingEvent: false;
};

export type HandlerData<T, R> = StandardResponse<T, R> | PollingResponse;

// Sending a single response object to the callback instead of multiple parameters so that the correct types can be inferred.
// For example, if you test that response.isPollingEvent is false the linter will then correctly infer that data is not undefined
export declare type SubscriptionHandler<T, R> = (response: HandlerData<T, R>) => R | undefined;

/**
 * Wrapping useSubscription to check if we have a socket - if no socket is established we will manually trigger the given handler with an extra parameter
 * so the caller knows whether it's a natural subscription event or if we are polling
 *
 * NOTE: Worth keeping in mind that if multiple components end up subscribing to the same socket notification, there is a chance they will have staggered intervals
 * and end up polling more frequently than intended. In those scenarios you could just manually use the usePollingFallback hook in one place, or this hook could be improved to
 * receive an ID and only create one interval
 */
export function useSubscription<Data = any, Result = Data, Variables = object>(
    args: UseSubscriptionArgs<Variables, Data>,
    handler?: SubscriptionHandler<Data, Result>,
): UseSubscriptionResponse<Result, Variables> {
    const [isPollingTest] = useWebsocketStatus((state) => [state.isPollingTest]);

    const handlerRef = useUpdatingRef<SubscriptionHandler<Data, Result> | undefined>(handler);

    usePollingFallback(
        () => {
            handlerRef.current?.({ prevData: undefined, data: undefined, isPollingEvent: true });
        },
        !args.noPollingFallback ? args.pollingInterval ?? websocketPollingInterval : null,
    );

    // Wrapping the given handler so we can pass it through to the standard urql useSubscription in the expected shape
    const passThroughHandler = useCallback<UrqlSubscriptionHandler<Data, Result>>(
        (prevData, data) => {
            // Trigger the given handler with the data in our our shape
            handlerRef?.current?.({ prevData, data, isPollingEvent: false });
            return data as any;
        },
        [handlerRef],
    );

    // Force subscription to be paused when polling test is active
    // NOTE: Since this will apply to all subscriptions and close them, the web socket will also get closed since it is set to "lazy" e.g. only be open when needed
    // Once isPollingTest is set back to false the subscriptions will then resubscribe when necessary and the socket will be opened again
    const passThroughArgs = useMemo(() => {
        return {
            ...args,
            pause: isPollingTest ? true : args.pause ?? false,
        };
    }, [args, isPollingTest]);

    // Pass through to standard urql subscription hook
    return useUrqlSubscription(passThroughArgs, passThroughHandler);
}
