import React, { useEffect, useMemo, useState } from 'react';
import { createClient, dedupExchange, Provider, subscriptionExchange, errorExchange } from 'urql';
import { Cache, cacheExchange } from '@urql/exchange-graphcache';
import { gql, makeOperation } from '@urql/core';
import { persistedFetchExchange } from '@urql/exchange-persisted-fetch';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { authExchange } from '@urql/exchange-auth';
import { devtoolsExchange } from '@urql/devtools';
import {
    ChakraProvider,
    createStandaloneToast,
    extendTheme,
    theme as baseTheme,
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import env, { getEnvironmentVariable } from '../../utils/env';
import theme from '../../theme/index';
import { Pages } from './Pages';
import { keycloak, refreshToken } from '../../utils/auth/keycloak';
import AppInitialisingLoader from '../Loaders/AppInitialisingLoader';
import jwt_decode from 'jwt-decode';
import { I18nProvider } from '../../utils/i18n';
import { ability, AbilityContext, updateAbility } from '../../utils/auth';
import { User, AuthContext, useAuth } from '../../utils/auth/state';
import { useClientConfig } from '../../utils/config';
import { CompleteProfileModal } from '../Users/CompleteProfileModal';
import { DocumentHead } from './DocumentHead';
import ConfigWrapper from './Config';
import { datadogRum } from '@datadog/browser-rum';
import { UserProvider, useUser } from '../../utils/stores/UserStore';
import { websocketClient } from '../../utils/websocket/websocket';
import { WebSocketStatus } from '../WebSocket/WebSocketStatus';

let networkProblems = false;

let enablePq = true;
const lsPq = localStorage.getItem('ENABLE_PERSISTED_QUERIES');
if (lsPq !== undefined && lsPq !== null) {
    enablePq = lsPq === 'true';
}

const urqlClient = createClient({
    url: getEnvironmentVariable('VITE_GRAPHQL_BASE_URL'),
    requestPolicy: 'cache-and-network',

    exchanges: [
        devtoolsExchange,
        dedupExchange,
        cacheExchange({
            // schema: IntrospectedSchema,
            keys: {
                // BidListRespone only changes when new bids are added
                BidListResponse: (data: any) => data.data.length,
                // AuctionBidSummary and AuctionBid only change when there is a new topBid
                AuctionBid: (data: any) => data.topBid.id,
                AuctionBidSummary: (data: any) => data.topBid.id,
                // TODO: Add keys to these so they can be cached
                PageData: () => null,
                CompanyListResponse: () => null,
                TradeListResponse: () => null,
                NotificationListResponse: () => null,
                TradeCompanyListResponse: () => null,
                ChatMessageRelayResponse: () => null,
                DocumentListResponse: () => null,
                TradeNoteListResponse: () => null,
                Metrics: () => null,
                TermsheetReviewChatRelayResponse: () => null,
                TermsheetListResponse: () => null,
                ChatThreadListResponse: () => null,
                TermsheetReviewChatListResponse: () => null,
            },
            resolvers: {
                Query: {
                    // companies: simplePagination(),
                },
            },
            updates: {
                Mutation: {
                    // API just returns true/false for this so we have to manually update our cache for the user
                    enableUser: (_result: any, args: any, cache: Cache, _info: any) => {
                        if (_result.enableUser) {
                            cache.writeFragment(
                                gql`
                                    fragment _ on User {
                                        id
                                        active
                                    }
                                `,
                                {
                                    id: args.userId,
                                    active: true,
                                },
                            );
                        }
                    },
                    // API just returns true/false for this so we have to manually update our cache for the user
                    disableUser: (_result: any, args: any, cache: Cache, _info: any) => {
                        if (_result.disableUser) {
                            cache.writeFragment(
                                gql`
                                    fragment _ on User {
                                        id
                                        active
                                    }
                                `,
                                {
                                    id: args.userId,
                                    active: false,
                                },
                            );
                        }
                    },
                },
                Subscription: {
                    // gonna leave this here as an example of how to do complex cache overrides
                    // https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#updating-lists-or-links
                    // tradeDetailsUpdate: (_result, args, cache, _info) => {
                    //     const fields = cache
                    //         .inspectFields('Query')
                    //         .filter((field) => field.fieldName === 'trades')
                    //         .forEach((field) => {
                    //             cache.updateQuery(
                    //                 {
                    //                     query: GetTradesDocument,
                    //                     variables: { query: field.arguments?.query },
                    //                 },
                    //                 (data: any) => {
                    //                     const allowedStatuses = (field.arguments?.query as any)
                    //                         .tradeStatus;
                    //                     console.log(data);
                    //                     console.log(allowedStatuses);
                    //                     if (data?.trades && data.trades && allowedStatuses) {
                    //                         data.trades.data = data.trades.data.push(_result);
                    //                         data.trades.data = data.trades.data.filter(
                    //                             (trade: Trade) =>
                    //                                 allowedStatuses.includes(trade.tradeStatus),
                    //                         );
                    //                     }
                    //                     return data;
                    //                 },
                    //             );
                    //         });
                    // },
                    // onDocumentSubscription(result, _args, cache, _info) {
                    //     cache.updateQuery({ query: GetDocumentsForTradeDocument }, (data) => {
                    //         console.log(data);
                    //         return data;
                    //     });
                    // },
                },
            },
        }),
        subscriptionExchange({
            forwardSubscription: (operation) => ({
                subscribe: (sink) => ({
                    unsubscribe: websocketClient.subscribe(operation, sink),
                }),
            }),
        }),
        authExchange({
            addAuthToOperation: ({ authState, operation }: { authState: any; operation: any }) => {
                if (!authState || !authState.token) {
                    console.warn(`No auth state detected.`);
                    return operation;
                }

                const fetchOptions =
                    typeof operation.context.fetchOptions === 'function'
                        ? operation.context.fetchOptions()
                        : operation.context.fetchOptions || {};

                return makeOperation(operation.kind, operation, {
                    ...operation.context,
                    fetchOptions: {
                        ...fetchOptions,
                        headers: {
                            ...fetchOptions.headers,
                            Authorization: `Bearer ${authState.token}`,
                        },
                    },
                });
            },
            willAuthError: ({ authState }) => {
                if (!authState) {
                    return true;
                }
                const currTime = Math.floor(new Date().getTime() / 1000);
                const token = jwt_decode(authState.token) as any;
                if (token.exp && token.exp < currTime) {
                    console.warn(`Token is expired, refreshing before continuing.`);
                    return true;
                }
                // e.g. check for expiration, existence of auth etc
                return false;
            },
            didAuthError: ({ error }: { error: any }) => {
                return error.graphQLErrors.some(
                    (e: any) =>
                        e.extensions?.response?.statusCode === 401 ||
                        e.extensions?.response?.error?.includes('Unauthorized'),
                );
            },
            getAuth: async ({ authState }) => {
                if (!authState) {
                    const { token } = keycloak;
                    if (token) {
                        return { token };
                    }
                    return null;
                }

                if (await refreshToken()) {
                    const { token } = keycloak;
                    if (token) {
                        return { token };
                    }
                }

                keycloak.logout({ redirectUri: window.location.origin + '/' });

                return null;
            },
        }),
        errorExchange({
            onError(error) {
                const toast = createStandaloneToast({ theme });
                if (error.networkError && !networkProblems) {
                    toast({
                        title: 'Encountering Network Problems',
                        description:
                            "Your computer is currently encountering network issues and can't establish a connection to our service. This should resolve automatically - if it does not, please contact GLX Support.",
                        status: 'error',
                        duration: 10000,
                        isClosable: true,
                        onCloseComplete: () => {
                            networkProblems = false;
                        },
                    });
                    networkProblems = true;
                }
                if (env.showGlobalError) {
                    error?.graphQLErrors.map(({ message, path }) => {
                        if (message !== 'PersistedQueryNotFound') {
                            let error = message;
                            if (path) {
                                error = `Could not access ${path}: ${error}`;
                            }
                            toast({
                                title: 'Server Error',
                                description: error,
                                status: 'error',
                                duration: 5000,
                            });
                        }
                    });
                }
            },
        }),
        persistedFetchExchange({
            preferGetForPersistedQueries: enablePq,
        }),
        multipartFetchExchange,
    ],
});

function AppLoader({ authReady = false }: { authReady?: boolean }) {
    const config = useClientConfig();

    // merge theme with config
    const mergedTheme = useMemo(() => {
        return extendTheme(baseTheme, theme, config.theme ?? {});
    }, [config?.theme]);

    const user = useUser();

    const isReady = authReady && !!config && user;

    return (
        <ChakraProvider resetCSS theme={mergedTheme}>
            <I18nProvider>
                <DocumentHead />
                <WebSocketStatus />
                {!isReady && <AppInitialisingLoader />}
                {isReady && (
                    <AbilityContext.Provider value={ability}>
                        <Pages />
                        <CompleteProfileModal />
                    </AbilityContext.Provider>
                )}
            </I18nProvider>
        </ChakraProvider>
    );
}

function App() {
    const { initialized, keycloak } = useKeycloak();
    const [user, setUser] = React.useState<User | undefined>();

    useEffect(() => {
        if (initialized && keycloak.authenticated && keycloak.token) {
            const user = jwt_decode<User>(keycloak.token);
            user.username = user.preferred_username;
            updateAbility(user);
            datadogRum.setUser({
                id: user.userId,
                name: user.username,
                email: user.email,
            });
            setUser(user);

            if (!user.company_id) {
                console.error(
                    'Company ID was not available in User claims. Is your user mapper configured?',
                );
            }
        }
    }, [initialized, keycloak.authenticated, keycloak.token]);

    const authReady = initialized && keycloak.authenticated && !!user;

    return (
        <AuthContext.Provider value={{ user }}>
            <Provider value={urqlClient}>
                <UserProvider username={user?.username}>
                    <ConfigWrapper authReady={authReady}>
                        <AppLoader authReady={authReady} />
                    </ConfigWrapper>
                </UserProvider>
            </Provider>
        </AuthContext.Provider>
    );
}

export default App;
