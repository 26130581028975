import { ClientTermsheet } from '../../pages/Initiate/trade-types';
import { TermsheetType } from '../../gql/generated/types';
import { map } from 'lodash';
import { PartialClientTermsheetTerm } from '../../pages/Initiate/trade-types';
import { getTermsheetRow } from '../../pages/Initiate/State/termsheetUtils';

export function getGlxDefaultTermsheet(): ClientTermsheet {
    return {
        name: null,
        termsheetType: TermsheetType.Template,
        terms: constructRows([
            {
                label: 'Seller',
                value: '',
                scope: 'TBD',
                type: 'TEXT',
                fixed: true,
                locked: false,
                id: 'seller',
            },
            {
                label: 'Buyer',
                value: '',
                scope: 'TBD',
                type: 'TEXT',
                fixed: true,
                locked: false,
                id: 'buyer',
            },
            {
                label: 'Quantity',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Delivery Terms',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },

            {
                label: 'Contract Price',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Loading Port',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Loading Window',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Discharge Port',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Discharge Window',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Vessel',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Demurrage',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Allowed Laytime',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Title and Risk',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Measurement',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Specifications',
                value: '',
                scope: 'ALL',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'Credit',
                value: '',
                scope: 'COMPANY',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
            {
                label: 'MSA',
                value: '',
                scope: 'COMPANY',
                type: 'TEXT',
                fixed: false,
                locked: false,
            },
        ]),
    };
}

// Will make sure each row is given the required proprties e.g. a uuid
function constructRows(rows: PartialClientTermsheetTerm[]) {
    return map(rows, (row) => getTermsheetRow(row));
}
