import React, { useRef, useEffect } from 'react';
import { create as timesyncCreate } from 'timesync';
import { useClient } from 'urql';
import { getEnvironmentVariable } from '../../utils/env';
import zustandCreate, { State } from 'zustand';
import { getDebugger } from '@app/components';

export const timeSyncDebugger = getDebugger(`TimeSync`);

export interface TimeSyncStore extends State {
    offset: number;
    setOffset: (offset: number) => void;
}

export const useTimeSync = zustandCreate<TimeSyncStore>((set, get) => {
    return {
        offset: 0,
        setOffset: (offset: number) => {
            set({ offset });
        },
    };
});

export function TimeSyncProvider({
    isReady = false,
    children,
}: {
    isReady?: boolean;
    children: React.ReactNode;
}) {
    const urql = useClient();
    const [setOffset] = useTimeSync((state) => [state.setOffset]);

    const isInitialisedRef = useRef(false);
    useEffect(() => {
        if (!isReady || isInitialisedRef.current) {
            return;
        }

        // If needed this timeSync object can be added to the store, but for now just storing the offset
        const timeSync = timesyncCreate({
            server: getEnvironmentVariable('VITE_GRAPHQL_BASE_URL'),
            interval: 60000,
        });

        timeSync.on('change', (offset) => {
            timeSyncDebugger('Changed timeSync offset: ' + offset + ' ms');
            setOffset(offset);
        });

        timeSync.send = async (to: string, data: object, timeout: number) => {
            if (isReady) {
                const res = await urql
                    .query(`query TimeSync($data: JSON!) { timeSync(data: $data) }`, { data })
                    .toPromise();
                const result = res.data.timeSync;
                timeSync.receive(to, result);
            }
        };

        isInitialisedRef.current = true;

        return () => {
            timeSync.destroy();
        };
    }, [isReady, urql, setOffset]);

    return <>{children}</>;
}
