import React, { useMemo } from 'react';
import {
    ButtonGroup,
    Circle,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { maxWebsocketRetryCount, useWebsocketStatus } from '../../utils/websocket/websocketStore';
import { Button } from '@app/components';
import { websocketClient } from '../../utils/websocket/websocket';
import store from 'store';

/**
 * Intended for debug purposes
 * Shows the websocket connection status as a coloured dot in the top right of the page. When clicked, provides some information / debug options for the websocket status.
 * NOTE: Only visible if SHOW_WEBSOCKET_STATUS is set to true in your local storage
 */
export function WebSocketStatus() {
    const showComponent = store.get('SHOW_WEBSOCKET_STATUS') === true;
    return showComponent ? <WebSocketStatusInner /> : null;
}

function WebSocketStatusInner() {
    const [connected, connecting, attemptCount, shouldPoll, isPollingTest, togglePollingTest] =
        useWebsocketStatus((state) => [
            state.connected,
            state.connecting,
            state.attemptCount,
            state.shouldPoll,
            state.isPollingTest,
            state.togglePollingTest,
        ]);

    const isPolling = shouldPoll;

    const status = useMemo(() => {
        if (connected)
            return {
                color: isPolling ? 'red' : 'green',
                message: 'WebSocket connected',
            };
        if (connecting) return { color: 'orange', message: 'WebSocket is connecting' };
        return {
            color: 'red',
            message: `WebSocket not connected.`,
        };
    }, [connected, connecting, isPolling]);

    return (
        <Popover isLazy>
            <PopoverTrigger>
                <Circle
                    id="websocket-status"
                    bg={status.color}
                    position="fixed"
                    zIndex="toast"
                    top={1}
                    right={1}
                    size={2}
                />
            </PopoverTrigger>
            <PopoverContent rootProps={{ zIndex: 'popover' }}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                    <Text whiteSpace="pre-line">
                        {status.message}
                        {/* Hiding attempt count while polling test is active as all subscriptions get killed which causes the websocket to close */}
                        {!connected &&
                            !isPollingTest &&
                            `\nAttempt count: ${attemptCount} of ${maxWebsocketRetryCount}`}
                    </Text>
                    {isPolling && (
                        <Text as="strong" display="block">
                            {isPollingTest ? 'Polling Test Active' : 'Polling Enabled'}
                        </Text>
                    )}
                    {(connected || isPollingTest) && (
                        <ButtonGroup marginTop={2}>
                            <Tooltip
                                label={
                                    isPollingTest
                                        ? 'Stop forced polling, websocket connection should return'
                                        : 'Force into polling state, websocket may be closed as a result of the subscriptions being paused'
                                }
                            >
                                <Button size="sm" onClick={() => togglePollingTest()}>
                                    {isPollingTest ? 'Stop' : 'Start'} Polling
                                </Button>
                            </Tooltip>
                            {!isPollingTest && (
                                <Tooltip label="Closes the socket, which should get reconnected">
                                    <Button size="sm" onClick={() => websocketClient.terminate()}>
                                        Close Socket
                                    </Button>
                                </Tooltip>
                            )}
                        </ButtonGroup>
                    )}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
