export default {
    categories: {
        company: 'Companies',
        user: 'Users',
        trade: 'Trades',
        chat: 'Chat',
        note: 'Notes',
        template: 'Templates',
        activityLog: 'Activity Log',
        termsheetreview: 'Reviews',
        dashboard: 'Dashboard',
        administration: 'Administration',
    },
    'mycompany.manage': {
        name: 'Manage My Company',
        description: 'This will allow a user to update the details of your company.',
    },
    'company.read': {
        name: 'Read Counterparties',
        description:
            'This will allow a user to view the details of all Counterparties. The user will not be able to add new Counterparties or update existing ones.',
    },
    'company.manage': {
        name: 'Manage Counterparties',
        description:
            'This will allow a user to create and manage counterparties, including disabling and enabling their access.',
    },
    'mycompanyuser.manage': {
        name: 'Manage My Company Users',
        description:
            'This will allow a user to create and manage users for your company, including disabling and enabling their access.',
    },
    'companyuser.manage': {
        name: 'Manage Counterparty Users',
        description:
            'This will allow a user to create and manage users for any counterparty, including disabling and enabling their access.',
    },
    'permission.manage': {
        name: 'Manage Permissions',
        description:
            'This will allow a user to edit the roles and permissions of themselves and other users, as well as allowing them to create and edit user roles.',
    },
    'trade.read': {
        name: 'Read Trades',
        description:
            'This will allow a user to view existing trades but not initiate new trades or manage bids/offers.',
    },
    'trade.manage': {
        name: 'Manage Trades',
        description:
            'This will allow a user to initiate, close and extend trades. It will also allow the user to update the trade ref., general information and manage documents.',
    },
    'bid.award': {
        name: 'Award Bids/Offers',
        description:
            'This will allow a user to accept an active bid or offer received from a counterparty.',
    },
    'bid.manage': {
        name: 'Manage Bids/Offers',
        description:
            'This will allow a user to manage working drafts, send and withdraw bids and offers, and exchange draft term sheets with counterparties',
    },
    'termsheetreview.create': {
        name: 'Create Reviews',
        description:
            'This will allow a user to request a term sheet review. The user will only be able to view reviews that they have requested.',
    },
    'termsheetreview.review': {
        name: 'Review Term Sheets',
        description:
            'This will allow a user to be sent term sheets for them to review and give feedback. The user will only be able to view reviews requested of them.',
    },
    'termsheetreview.readall': {
        name: 'Read All Reviews',
        description:
            'This will allow a user to view all reviews regardless of which user requested them or who the review was sent to.',
    },
    'chat.read': {
        name: 'Read Chat Messages',
        description: 'This will allow a user to read but not send chat messages for all trades.',
    },
    'chat.manage': {
        name: 'Send Chat Messages',
        description: 'This will allow a user to read and send chat messages for all trades.',
    },
    'chat.export': {
        name: 'Export Chat Messages',
        description: 'This will allow a user to export a log of all chat messages.',
    },
    'note.read': {
        name: 'Read Notes',
        description: 'This will allow a user to read but not add notes for all trades.',
    },
    'note.manage': {
        name: 'Add Notes',
        description: 'This will allow a user to read and add notes for all trades.',
    },
    'template.read': {
        name: 'Read Term Sheet Templates',
        description:
            'This will allow a user to view the details of all templates. The user will not be able to add new templates or update existing ones.',
    },
    'template.manage': {
        name: 'Manage Term Sheet Templates',
        description:
            'This will allow a user to create and update term sheet templates that can be used in trade initiation.',
    },
    'activitylog.read': {
        name: 'Read Activity Log',
        description:
            'This will allow a user to access the Activity Log that is provided for audit purposes.',
    },
    'dashboard.extended': {
        name: 'Dashboard Analytics',
        description: 'This will allow a user to see the trade analytics on the dashboard.',
    },
    'auth.manage': {
        name: 'Manage SSO',
        description: `This will allow a user to manage the single sign-on (SSO) settings for your organisation. Your SSO settings will affect users' ability to login and should be managed only by administrators.`,
    },
};
