import { css } from '@chakra-ui/react';
import { assign } from 'lodash';
import theme from '../../../theme';

const parts = [
    'formControl',
    'formLabel',
    'input',
    'checkbox',
    'requiredIndicator',
    'errorIndicator',
    'reactSelect',
    'icon',
];

const generalYPadding = 1,
    generalXPadding = 2,
    placeholderColor = 'gray.400';

function baseFormControlStyles(props) {
    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minHeight: '10',
        position: 'relative',
        borderWidth: 1,
        borderRadius: 'md',
        fontSize: 'md',
        ...(props.showContainerStyles && {
            bg: 'white',
            borderColor: 'gray.200',
            _focus: {
                zIndex: 2, //allows border to show above siblings if they're being forced to overlap
                borderColor: 'blue.500',
                boxShadow: 'focusOutline',
                _invalid: {
                    zIndex: 2, //ensure focussed input border still has priority
                },
            },
            _invalid: {
                zIndex: 1, //allows border to show above siblings if they're being forced to overlap
                borderColor: 'red.500',
                _focus: {
                    boxShadow: 'errorOutline',
                },
            },
            _disabled: {
                opacity: 0.5,
                bg: 'gray.100',
                cursor: 'not-allowed',
            },
            _readOnly: {
                bg: 'transparent',
                borderColor: 'transparent',
                _focus: {
                    boxShadow: 'none',
                },
            },
        }),
        ...(!props.showContainerStyles && {
            borderColor: 'transparent',
            _disabled: {
                opacity: 0.5,
            },
        }),
    };
}

function baseFormLabelStyles(props) {
    return {
        display: 'block',
        paddingTop: generalYPadding,
        paddingX: generalXPadding,
        paddingLeft: !!props.leftIcon ? 10 : undefined,
        paddingRight: !!props.rightIcon ? 10 : undefined,
        lineHeight: 'shorter',
        _invalid: {
            color: 'red.500',
            _readOnly: {
                color: 'inherit',
            },
        },
        _disabled: {
            cursor: 'not-allowed',
        },
    };
}

function baseInputStyles(props) {
    return {
        outline: 'none',
        width: '100%',
        bg: 'transparent',
        paddingY: generalYPadding,
        paddingX: generalXPadding,
        paddingLeft: !!props.leftIcon ? 10 : undefined,
        paddingRight: !!props.rightIcon ? 10 : undefined,
        _disabled: {
            cursor: 'not-allowed',
        },
        '::placeholder, ::-webkit-input-placeholder': {
            color: placeholderColor,
        },
    };
}

function baseCheckboxStyles(props) {
    const base = {
        alignItems: 'start',
    };
    if (props.label) {
        return assign(base, {
            marginY: 2,
            marginLeft: 2,
        });
    }
    return base;
}

function baseIndicatorStyles(props) {
    return {
        marginLeft: 1,
        color: 'red.500',
    };
}

function baseErrorIndicatorStyles(props) {
    return {
        width: '4',
        height: '4',
        padding: 'px',
        borderRadius: '2xl',
        backgroundColor: '#d71638',
        color: 'white',
        fontSize: 'icon',
        textAlign: 'center',
        marginLeft: '1',
        display: 'inline-block',
        verticalAlign: 'text-bottom',
    };
}

//Object returned here needs to match the support format for react-select custom styles
export const reactSelectStyles = {
    container: (base) => ({
        ...base,
        ...css({
            position: 'static',
        })(theme),
    }),
    control: (base) => ({
        ...base,
        ...css({
            position: 'static',
            padding: 0,
            border: 'none',
            boxShadow: 'none',
            background: 'none',
            height: '100%',
            minHeight: 0,
        })(theme),
    }),
    valueContainer: (base) => ({
        ...base,
        ...css({
            paddingX: generalXPadding,
            paddingY: generalYPadding,
        })(theme),
    }),
    indicatorsContainer: (base) => ({
        ...base,
        ...css({})(theme),
    }),
    dropdownIndicator: (base) => ({
        ...base,
        ...css({
            paddingY: 0,
            paddingX: 0.5 * generalXPadding,
        })(theme),
    }),
    clearIndicator: (base) => ({
        ...base,
        ...css({
            // display: 'none',
            paddingY: 0,
            paddingX: 0.5 * generalXPadding,
        })(theme),
    }),
    indicatorSeparator: (base) => ({
        ...base,
        ...css({
            // display: 'none',
        })(theme),
    }),
    input: (base) => ({ ...base, ...css({ margin: 0, padding: 0 })(theme) }),
    singleValue: (base) => ({
        ...base,
        ...css({ margin: 0, padding: 0, color: 'inherit' })(theme),
    }),
    multiValue: (base) => ({
        ...base,
        ...css({
            bgColor: 'transparent',
            margin: 0.5,
        })(theme),
    }),
    multiValueLabel: (base, state) => {
        const isInvalid = !state.isValid;
        return {
            ...base,
            ...css({
                color: isInvalid ? 'white' : undefined,
                bgColor: isInvalid ? 'error.500' : 'gray.100',
                fontSize: 'sm',
                px: 1,
                py: 0.5,
                borderLeftRadius: 'md',
                borderRightRadius: state?.isDisabled ? 'md' : 'none',
            })(theme),
        };
    },
    multiValueRemove: (base, state) => {
        const display = state?.isDisabled ? 'none' : 'inherit';
        const isInvalid = !state.isValid;
        return {
            ...base,
            ...css({
                color: isInvalid ? 'white' : undefined,
                bgColor: isInvalid ? 'error.500' : 'gray.100',
                paddingX: 0.5,
                display,
                borderRightRadius: 'md',
                borderLeftRadius: 'none',
                _hover: {
                    color: isInvalid ? 'white' : 'red.600',
                    bgColor: isInvalid ? 'error.700' : 'red.200',
                },
            })(theme),
        };
    },
    menu: (base, state) => {
        return {
            ...base,
            ...css({
                zIndex: 'popover',
            })(theme),
        };
    },
    menuPortal: (base, state) => {
        return {
            ...base,
            ...css({
                zIndex: 'popover',
            })(theme),
        };
    },
    placeholder: (base, state) => {
        return {
            ...base,
            ...css({
                color: placeholderColor,
            })(theme),
        };
    },
};

function baseReactSelectStyles(props) {
    const baseStyles = reactSelectStyles;
    return {
        ...baseStyles,
        container: (base, state) => {
            return {
                ...baseStyles.container(base, state),
                ...css({
                    paddingLeft: !!props.leftIcon ? 8 : undefined,
                    paddingRight: !!props.rightIcon ? 8 : undefined,
                })(theme),
            };
        },
        indicatorsContainer: (base, state) => {
            return {
                ...baseStyles.indicatorsContainer(base, state),
                ...css({
                    display: props.isReadOnly ? 'none' : undefined,
                    transform: props.label ? 'translateY(-0.5rem)' : undefined, //shift up slightly so it's more central (accounting for height of label)
                })(theme),
            };
        },
    };
}

function baseIconStyles(props, iconPosition) {
    return {
        display: 'flex',
        position: 'absolute',
        [iconPosition]: 0,
        width: 10,
        pointerEvents: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'full',
    };
}

const baseStyle = (props) => {
    return {
        formControl: baseFormControlStyles(props),
        formLabel: baseFormLabelStyles(props),
        input: baseInputStyles(props),
        checkbox: baseCheckboxStyles(props),
        requiredIndicator: baseIndicatorStyles(props),
        errorIndicator: baseErrorIndicatorStyles(props),
        reactSelect: baseReactSelectStyles(props),
        iconLeft: baseIconStyles(props, 'left'),
        iconRight: baseIconStyles(props, 'right'),
    };
};

//By default, in read only mode, the form control component will be styled to look more "display" friendly.
//This variant, When in read only mode, will style it so it's obvious that it's still an input
const inputOnlyVariant = (props) => {
    return {
        formControl: {
            _readOnly: {
                bg: 'gray.100',
                borderColor: 'inherit',
            },
        },
    };
};

export default {
    parts,
    baseStyle,
    variants: {
        input: inputOnlyVariant,
    },
};
