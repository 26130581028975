import React from 'react';
import { useAbility } from '../../utils/auth';
import { Abilities } from '@app/lib';
import Unauthorized from '../../pages/Unauthorized';
import { filter, isArray } from 'lodash';

export interface RequirePermissionConfig<T extends Abilities = Abilities> {
    fallbackNode: React.ReactNode;
    permissions: [T[0], T[1]][];
    permissionType: 'any' | 'all';
    check: boolean; //extra checks independant of permissions, can extend in future to take a function too if needed
}
type PartialConfig<T extends Abilities = Abilities> = Partial<RequirePermissionConfig<T>>;

export interface RequirePermissionProps<T extends Abilities = Abilities> extends PartialConfig<T> {
    children: React.ReactNode;
}

const defaultConfig: RequirePermissionConfig = {
    permissions: [],
    permissionType: 'any',
    check: true,
    fallbackNode: <Unauthorized />,
};

//Check if user is allowed to do requested action - if they're allowed it will render the child node, otherwise will render the fallback node
export function RequirePermission<T extends Abilities = Abilities>({
    children,
    ...restConfig
}: RequirePermissionProps<T>) {
    const config = {
        ...defaultConfig,
        ...restConfig,
    };
    const { permissions, permissionType, fallbackNode } = config;

    const ability = useAbility();
    const userPermissions = filter(permissions, (permission) =>
        ability.can(permission[0] as any, permission[1] as any),
    );
    const hasPermission =
        config.check &&
        (permissionType === 'any'
            ? userPermissions.length
            : userPermissions.length === permissions.length);

    if (hasPermission) {
        return <>{children}</>;
    } else {
        return <>{fallbackNode}</>;
    }
}

export function withPermission<T extends Abilities = Abilities>(
    successNode: RequirePermissionProps<T>['children'],
    givenConfig?: PartialConfig<T> | RequirePermissionConfig<T>['permissions'], // can either pass the config or just the permission array
) {
    let config: Partial<RequirePermissionConfig> = defaultConfig;
    if (isArray(givenConfig)) {
        config = { ...config, permissions: givenConfig };
    } else if (givenConfig) {
        config = { ...config, ...givenConfig };
    }
    return <RequirePermission {...config}>{successNode}</RequirePermission>;
}
