import React from 'react';
import { SidebarItem, SidebarItemProps } from '@app/components';
import { forwardRef, RightJoinProps } from '@chakra-ui/system';
import { Link, NavLinkProps, LinkProps } from '../../utils/pages/Link';

export type SidebarLinkProps = RightJoinProps<LinkProps, SidebarItemProps>;

// Renders a sidebar item as a Link from react-router - https://reactrouter.com/web/api/Link
export const SidebarLink = forwardRef(function SidebarLink({ ...rest }: SidebarLinkProps, ref) {
    return <SidebarItem as={Link} preload={false} ref={ref} {...rest} />;
});

export type SidebarNavLinkProps = RightJoinProps<NavLinkProps, SidebarItemProps>;

// Renders a sidebar item as a NavLink from react-router - https://reactrouter.com/web/api/NavLink
export const SidebarNavLink = forwardRef(function SidebarNavLink(
    { ...rest }: SidebarNavLinkProps,
    ref,
) {
    return <SidebarItem as={Link} isNavLink preload={false} ref={ref} {...rest} />;
});
