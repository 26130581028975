import React from 'react';
import { forwardRef } from '@chakra-ui/system';
import { Box } from '@chakra-ui/react';
import { SocketErrorBanner } from './SocketErrorBanner';

export const AppBanners = forwardRef(function Banners(props, ref) {
    return (
        <Box ref={ref} position="fixed" top="0" left="0" right="0" role="banner" zIndex="banner">
            <SocketErrorBanner />
        </Box>
    );
});
