import React, { useRef } from 'react';
import { useAbility } from '../../utils/auth';
import { useDisclosure } from '@chakra-ui/hooks';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Icon,
    IconButton,
    IconButtonProps,
    Flex,
} from '@chakra-ui/react';
import { MdMenu, MdGroup, MdSwapHoriz, MdAssessment, MdRateReview } from 'react-icons/md';
import { HamburgerMenuItem, HamburgerMenuNavLink } from './Hamburger/HamburgerMenuItem';
import { appPaths } from '../../utils/routeConfig';
import { useKeycloak } from '@react-keycloak/web';
import { generatePath } from 'react-router-dom';
import { RiLogoutBoxRFill } from 'react-icons/ri';
import { HiTemplate } from 'react-icons/hi';
import { useGetFeatureFlag } from '../../utils/config';
import { useUser } from '../../utils/stores/UserStore';
import { FeatureFlags } from '@app/lib';

export type HeaderHamburgerProps = Partial<IconButtonProps>;

export function HeaderHamburger({ ...rest }: HeaderHamburgerProps) {
    const { keycloak } = useKeycloak();
    const ability = useAbility();
    const user = useUser();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef<HTMLButtonElement | null>(null);

    const onItemClick = () => {
        onClose();
    };

    const onLogout = () => {
        onItemClick();
        keycloak.logout({ redirectUri: window.location.origin + '/' });
    };

    const termSheetReviewEnabled = useGetFeatureFlag(FeatureFlags.ReviewTermSheet);

    return (
        <>
            <IconButton
                ref={btnRef}
                variant="ghost"
                onClick={onOpen}
                icon={<Icon as={MdMenu} fontSize="3xl" />}
                aria-label="Menu"
                {...rest}
            >
                Open
            </IconButton>
            <Modal isOpen={isOpen} onClose={onClose} size="entire">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton position="absolute" left={2} top={2} />
                    <ModalBody overflow="auto">
                        <Flex
                            direction="column"
                            minHeight="full"
                            width="fit-content"
                            margin="0 auto"
                            justifyContent="center"
                            paddingTop={4}
                        >
                            <HamburgerMenuNavLink
                                end
                                to={generatePath(appPaths.home, {})}
                                icon={MdAssessment}
                                onClick={onItemClick}
                            >
                                Dashboard
                            </HamburgerMenuNavLink>
                            <HamburgerMenuNavLink
                                to={generatePath(appPaths.trades, {})}
                                icon={MdSwapHoriz}
                                onClick={onItemClick}
                            >
                                Trades
                            </HamburgerMenuNavLink>
                            {ability.can('read', 'company') && user?.company?.owner && (
                                <HamburgerMenuNavLink
                                    to={generatePath(appPaths.counterparties, {})}
                                    icon={MdGroup}
                                    onClick={onItemClick}
                                >
                                    Counterparties
                                </HamburgerMenuNavLink>
                            )}
                            {ability.can('read', 'template') && (
                                <HamburgerMenuNavLink
                                    to={generatePath(appPaths.templates, {})}
                                    icon={HiTemplate}
                                    onClick={onItemClick}
                                >
                                    Templates
                                </HamburgerMenuNavLink>
                            )}
                            <HamburgerMenuNavLink
                                to={generatePath(appPaths.myCompany)}
                                end
                                icon={MdGroup}
                                onClick={onItemClick}
                            >
                                My Company
                            </HamburgerMenuNavLink>
                            {termSheetReviewEnabled && ability.can('read', 'termsheetreview') && (
                                <HamburgerMenuNavLink
                                    to={generatePath(appPaths.reviews)}
                                    end
                                    icon={MdRateReview}
                                    onClick={onItemClick}
                                >
                                    Reviews
                                </HamburgerMenuNavLink>
                            )}
                            <HamburgerMenuItem icon={RiLogoutBoxRFill} onClick={onLogout}>
                                Logout
                            </HamburgerMenuItem>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
