export default {
    entity: 'Trade',
    uid: 'Trade ID',
    tradeRef: 'Trade Ref.',
    transactionType: 'Transaction Type',
    tradeType: 'Trade Type',
    trader: 'Trader',
    currentTrades: 'Current Trades',
    pastTrades: 'Past Trades',
    noTrades: 'No trades',
    create: 'Initiate',
};
