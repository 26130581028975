import trade from './trade';
import date from './date';
import dashboard from './dashboard';
import company from './company';
import counterparty from './counterparty';
import termsheet from './termsheet';
import template from './template';
import users from './users';
import activities from './activities';
import permissions from './permissions';
import review from './review';

export default {
    translation: {
        dashboard,
        trade,
        date,
        company,
        counterparty,
        termsheet,
        template,
        users,
        activities,
        permissions,
        review,
    },
};
