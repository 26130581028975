import { isNumber } from 'lodash';
import { useEffect } from 'react';
import { useUpdatingRef } from './useUpdatingRef';

export type IntervalCallback = () => void;

export function useInterval(callback?: IntervalCallback, delay?: null | number) {
    const callbackRef = useUpdatingRef<IntervalCallback | undefined>(callback);

    useEffect(() => {
        function tick() {
            callbackRef.current?.();
        }

        const id = isNumber(delay) ? setInterval(tick, delay) : null;
        return () => {
            id && clearInterval(id);
        };
    }, [delay, callbackRef]);
}
