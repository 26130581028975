import React, { useContext } from 'react';
import {
    Sidebar as BaseSidebar,
    SidebarIcon,
    SidebarProps as BaseSidebarProps,
} from '@app/components';
import { Center, Heading, Image, Spacer, Tooltip, Text } from '@chakra-ui/react';
import { SidebarLink, SidebarNavLink } from './SidebarLink';
import { useAbility } from '../../utils/auth';
import { appPaths } from '../../utils/routeConfig';
import { MdSwapHoriz, MdGroup, MdAssessment, MdRateReview } from 'react-icons/md';
import { HiTemplate } from 'react-icons/hi';
import { useGetClientConfigValue, useGetFeatureFlag } from '../../utils/config';
import { generatePath } from 'react-router-dom';
import { useHomeRoute } from '../../utils/permissions/useHomeRoute';
import { useUser } from '../../utils/stores/UserStore';
import env from '../../utils/env';
import { FeatureFlags } from '@app/lib';

export type SidebarProps = BaseSidebarProps;

export function Sidebar({ ...rest }: SidebarProps) {
    const ability = useAbility();
    const user = useUser();

    const logoPath = useGetClientConfigValue(
        'logoPath',
        'https://glx-customer-assets.s3.ap-southeast-2.amazonaws.com/glx/logo-glx.svg',
    );
    const termSheetReviewEnabled = useGetFeatureFlag(FeatureFlags.ReviewTermSheet);

    const { route: homeRoute } = useHomeRoute();
    const canSeeDashboard = ability.can('read', 'trade');

    return (
        <BaseSidebar {...rest}>
            <SidebarLink
                to={homeRoute}
                justifyContent="center"
                minHeight="70px"
                tooltip={canSeeDashboard ? 'Go to Dashboard' : ''}
            >
                <Image
                    src={logoPath}
                    aria-label="Logo"
                    width="100%"
                    height="auto"
                    transform="translateZ(0)" // Added to improve downscaling - tricks browser to use hardware acceleration
                />
            </SidebarLink>
            {canSeeDashboard && (
                <SidebarNavLink end to={generatePath(appPaths.home)}>
                    <SidebarIcon icon={MdAssessment}>Dashboard</SidebarIcon>
                </SidebarNavLink>
            )}
            {ability.can('read', 'trade') && (
                <SidebarNavLink to={generatePath(appPaths.trades)}>
                    <SidebarIcon icon={MdSwapHoriz}>Trades</SidebarIcon>
                </SidebarNavLink>
            )}
            {ability.can('read', 'company') && user?.company?.owner && (
                <SidebarNavLink to={generatePath(appPaths.counterparties)}>
                    <SidebarIcon icon={MdGroup}>Counterparties</SidebarIcon>
                </SidebarNavLink>
            )}
            {ability.can('read', 'template') && (
                <SidebarNavLink to={generatePath(appPaths.templates)}>
                    <SidebarIcon icon={HiTemplate}>Templates</SidebarIcon>
                </SidebarNavLink>
            )}
            {termSheetReviewEnabled && ability.can('read', 'termsheetreview') && (
                <SidebarNavLink to={generatePath(appPaths.reviews)}>
                    <SidebarIcon icon={MdRateReview}>Reviews</SidebarIcon>
                </SidebarNavLink>
            )}
            {/* leaving this here as an example of how to use the feature flags */}
            {/* {config.features.auctions && (
                <SidebarNavLink to={generatePath(appPaths.templates)}>
                    <SidebarIcon  icon={HiTemplate}>Auctions</SidebarIcon>
                </SidebarNavLink>
            )} */}
            <Spacer />
            <Center pb={2} px={2}>
                <Text
                    fontSize="10px"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    fontWeight="500"
                    color="gray.500"
                >
                    Version: {env.appVersion}
                </Text>
            </Center>
        </BaseSidebar>
    );
}
