import { matchPath, PathMatch, To } from 'react-router-dom';
import { appRoutes, RouteConfig } from '../routeConfig';

interface RouteMatch {
    route: RouteConfig;
    match: PathMatch<string>;
}

/**
 * Finds the route that matches the path
 * Ensures the that if the path is /foo/bar/qux that it matches /foo/bar/:id and not /foo/*
 *
 * routes is passed in to make testing easier
 */
export function getMatchingRouteFromPath(path: string, routes: RouteConfig[]): RouteConfig | null {
    const bestMatch = routes.reduce((best, route) => {
        // Match the path
        const match = matchPath(route.path, path);

        // If we dont have a best match yet use this one
        if (!best && match) return { match, route };

        // If the match is better bestMatch return it
        if (best && match) {
            const matchExtra = match.params['*'];
            const bestMatchExtra = best.match.params['*'];

            // If something matched without * it's the best
            if (!matchExtra) return { match, route };
            if (!bestMatchExtra) return best;

            // Otherwise whatever has the shortest extra is the best
            return bestMatchExtra.length < matchExtra.length ? best : { match, route };
        }

        return best;
    }, null as null | RouteMatch);

    return bestMatch?.route ?? null;
}

const allRoutes = Object.values(appRoutes);

/**
 * Find the route that matches the to
 */
export function getMatchingRoute(to: To) {
    // Handle to being a PartialPath
    const path = typeof to === 'object' ? to.pathname : to;
    // Find the route that matches the path
    return path ? getMatchingRouteFromPath(path, allRoutes) : null;
}
