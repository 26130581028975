import React, { useEffect, useRef, useState } from 'react';
import { Icon, Text, usePrevious } from '@chakra-ui/react';
import { RiWifiOffFill } from 'react-icons/ri';
import { Banner } from './Banner';
import { useWebsocketStatus } from '../../utils/websocket/websocketStore';
import { datadogRum } from '@datadog/browser-rum';
import { differenceInMilliseconds } from 'date-fns';

// How long to wait before showing the banner on an error
const SHOW_BANNER_DELAY = 10000;

export const SocketErrorBanner = ({ showDelay = SHOW_BANNER_DELAY }: { showDelay?: number }) => {
    const [isPollingTest, shouldPoll] = useWebsocketStatus((state) => [
        state.isPollingTest,
        state.shouldPoll,
    ]);

    // Waiting a few seconds after polling is enabled before showing the banner, just in case it fixes itself - no point alarming the user for nothing
    const [timeoutReached, setTimeoutReached] = useState(false);

    useEffect(() => {
        const timeout = shouldPoll
            ? setTimeout(() => setTimeoutReached(true), showDelay)
            : undefined;
        return () => {
            timeout && clearTimeout(timeout);
            setTimeoutReached(false);
        };
    }, [shouldPoll, showDelay]);

    const showBanner = isPollingTest || (shouldPoll && timeoutReached);
    const wasShowing = usePrevious(showBanner);

    const shownAtRef = useRef<Date | null>(null);

    useEffect(() => {
        if (showBanner && !wasShowing) {
            shownAtRef.current = new Date();
            datadogRum.addAction('socket banner shown');
        }
        if (!showBanner && wasShowing) {
            const timeShown = shownAtRef.current;
            datadogRum.addAction('socket banner removed', {
                shownAt: shownAtRef.current,
                durationMS: timeShown ? differenceInMilliseconds(new Date(), timeShown) : null,
            });
            shownAtRef.current = null;
        }
    }, [showBanner, wasShowing]);

    if (!showBanner) {
        return null;
    }

    return (
        <Banner colorScheme="warning" data-testid="socket-error-banner">
            <Icon as={RiWifiOffFill} boxSize="5" marginRight={2} />
            <Text>
                <Text as="strong" display="inline-block" marginRight={2}>
                    You are currently experiencing web socket issues
                </Text>
                <Text as="span" display="inline-block">
                    Updates to the page may be delayed. Please try refreshing or contact your system
                    administrator.
                </Text>
            </Text>
        </Banner>
    );
};
