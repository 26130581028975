import { To } from 'react-router-dom';
import { useState, useMemo, useCallback } from 'react';
import { getMatchingRoute } from './getMatchingRoute';

/**
 * Can be used to manually preload routes
 * This is more optimised than useDynamicPreload
 */
export function usePreload(to: To) {
    const [isPreloaded, setIsPreloaded] = useState(false);

    const route = useMemo(() => getMatchingRoute(to), [to]);
    const canPreload = Boolean(route && !isPreloaded);

    const handlePreload = useCallback(
        () => route?.component.preload() && setIsPreloaded(true),
        [route?.component],
    );

    return { handlePreload, route, canPreload, isPreloaded };
}

/**
 * Can be used to manually preload routes for dynamic `to` values
 */
export function useDynamicPreload() {
    const handlePreload = useCallback((to: To) => {
        const route = getMatchingRoute(to);
        if (route) route?.component.preload();
    }, []);

    return handlePreload;
}
