import React from 'react';
import { chakra, forwardRef, omitThemingProps } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import { createContext } from '@chakra-ui/react-utils';

const [InputGroupProvider, useInputGroupContext] = createContext({
    strict: false,
    name: 'GlxInputGroupContext',
});

export function useInputGroupProvider(props) {
    //Extract the props that are for the input group context
    const {
        isDisabled,
        isReadOnly,
        ...htmlProps //remaining props that will be set on the input group
    } = props;

    return {
        isReadOnly: !!isReadOnly,
        isDisabled: !!isDisabled && !isReadOnly, //if set to read only, no point also showing as disabled
        htmlProps,
    };
}

const InputGroup = forwardRef(function InputGroup(props, ref) {
    const ownProps = omitThemingProps(props);
    const { htmlProps, ...context } = useInputGroupProvider(ownProps);
    const _className = cx('glx-input__group', props.className);

    return (
        <InputGroupProvider value={context}>
            <chakra.div
                className={_className}
                ref={ref}
                __css={{
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                }}
                {...htmlProps}
            />
        </InputGroupProvider>
    );
});

export { InputGroup, useInputGroupContext };
