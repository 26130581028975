export default {
    entity: 'User',
    username: 'Username',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    position: 'Position',
    createdAt: 'Created At',
    updatedAt: 'Last Updated',
    noData: 'No users',
    active: 'Active',
};
