import React from 'react';
import { forwardRef } from '@chakra-ui/system';
import { Flex, FlexProps } from '@chakra-ui/react';

export const Banner = forwardRef(function Banner(
    { children, colorScheme = 'blue', ...rest }: FlexProps & { colorScheme?: string },
    ref,
) {
    return (
        <Flex
            bg={`${colorScheme}.200`}
            borderBottomColor={`${colorScheme}.600`}
            borderBottomWidth="2px"
            paddingY={1}
            paddingX={2}
            justifyContent="center"
            alignItems="center"
            {...rest}
        >
            {children}
        </Flex>
    );
});
