import { compact, forEach, isArray, isEmpty } from 'lodash';

/*
* When we are mutating an entity (e.g. a cargo), this entity might have links to other entities (e.g. ports, vessels)
* This function will do two things:
    - Set the entity link to null if no ID is present. This seems to be required otherwise the server will complain as I think it expects the ID to be there if the object is there.
    Also, the object specifically needs to be set to null, can't just delete the object.
    - If there is an ID, remove all other properties of that entity to avoid any accidental mutations.
    An example of an "accidental mutation" might be if we update the ID from port 1 to 3, but forget to change the "name" property.
    In this case, the old name would be sent up and the backend would end up changing the port name of port with ID 3 to be the name that was passed up.
*/
export const transformEntityLink = (data: Record<string, any>, entityName: string) => {
    if (data?.[entityName]) {
        if (isArray(data[entityName])) {
            //Transform each item in the array
            forEach(data[entityName], (entity, index) =>
                transformEntityLink(data[entityName], index),
            );
            data[entityName] = compact(data[entityName]); //remove any null values from the array
            if (isEmpty(data[entityName])) {
                data[entityName] = null; //set to null if array is now empty
            }
        } else if (!data[entityName].id) {
            data[entityName] = null;
        } else {
            data[entityName] = { id: data[entityName].id };
        }
    }
};
