import React from 'react';
import { Flex, Heading, SlideFade, Text } from '@chakra-ui/react';

const Unauthorized = () => (
    <Flex direction="row" h="full" justifyContent="center" alignItems="center">
        <SlideFade in={true} offsetY="50px">
            <Flex direction="column">
                <Heading size="4xl">403</Heading>
                <Text>NOT AUTHORIZED</Text>
            </Flex>
        </SlideFade>
    </Flex>
);

export default Unauthorized;
