import React, { useContext } from 'react';
import { get } from 'lodash';
import { defaultConfig, defaultFlags } from '@app/lib';

export const defaultCombinedConfig = {
    features: defaultFlags,
    config: defaultConfig,
};

export const ConfigContext = React.createContext(defaultCombinedConfig);
export const useConfig = () => useContext(ConfigContext);
export const useClientConfig = () => useConfig()?.config?.clientSettings;

export const useFeatureFlags = () => {
    const { features } = useConfig();
    return features;
};

export const useGetFeatureFlag = (flagName: string, defaultValue = false) => {
    const flags = useFeatureFlags();
    return get(flags, flagName, defaultValue);
};

export const useGetClientConfigValue = (configPath: string, defaultValue?: any) => {
    const clientConfig = useClientConfig();
    return get(clientConfig, configPath, defaultValue);
};
