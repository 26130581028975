import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { HeadingPortal } from '../Header';

export const PageSkeleton = () => {
    return (
        <>
            <HeadingPortal>
                <Skeleton width="100%" maxWidth={64} height={10} />
            </HeadingPortal>
            <Skeleton width="100%" height="100%" />
        </>
    );
};
