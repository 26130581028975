//Handles assigning the relevant props to the form control and label - input props handled in specific components depending on the input type

import React from 'react';
import { FormControlProps } from './types';
import { omit } from 'lodash';
import { BoxProps, ThemingProps, Box, Tooltip, Flex } from '@chakra-ui/react';
import { FormControl } from './FormControl';
import { FormControlLabel } from './FormControlLabel';

export type BaseControlWrapperProps = ThemingProps &
    FormControlProps &
    BoxProps & {
        children: React.ReactNode;
    };

const BaseControlWrapper = (props: BaseControlWrapperProps) => {
    const {
        hideLabel,
        helpText,
        displayTooltip,
        tooltipText,
        showErrorMessages,
        children,
        label,
        labelProps,
        ...formControlProps
    } = props;

    return (
        <Tooltip isDisabled={!displayTooltip} label={tooltipText}>
            <FormControl {...formControlProps} label={label}>
                {!hideLabel && (
                    <FormControlLabel
                        helpText={helpText || undefined}
                        showErrorMessages={showErrorMessages}
                        {...labelProps}
                    >
                        {label}
                    </FormControlLabel>
                )}
                {children}
            </FormControl>
        </Tooltip>
    );
};

export { BaseControlWrapper };
