import create, { State } from 'zustand';

/** How many times should the client try to reconnect before giving up */
export const maxWebsocketRetryCount = 5;
/** Time between polling intervals */
export const websocketPollingInterval = 10000;

export interface WebsocketStatusStore extends State {
    /** true while web socket client is connecting */
    connecting: boolean;
    /** true if the onClose event was called on the socket and it hasn't connected since - can technically be true even if it was never connected e.g. initial connection failed */
    closed: boolean;
    /** true while socket client is connected */
    connected: boolean;
    /** true if a socket connection has previously been established */
    supported: boolean;
    /** Keeping track of how many times it has tried to reconnect */
    attemptCount: number;
    /** Whether or not it has reached the max retry attempts and given up */
    maxAttemptsReached: boolean;
    /** Whether or not we should start polling for data and not rely on the websocket connection */
    shouldPoll: boolean;

    // Websocket event handlers
    onConnected: () => void;
    onConnecting: () => void;
    onClosed: (event: any) => void;
    onError: (errror: any) => void;

    // Testing helpers
    /** Whether or not we are testing polling i.e. ignoring actual subcription notifications and instead just forcing polling events  */
    isPollingTest: boolean;
    togglePollingTest: () => void;
}

const createWebsocketStore = () =>
    create<WebsocketStatusStore>((set, get) => ({
        connecting: false,
        closed: false,
        connected: false,
        supported: false,
        attemptCount: -1, // starting at -1 as the first attempt to connect doesn't count towards the total attempt count (will still try X times after the first failure)
        maxAttemptsReached: false,
        shouldPoll: false,

        onConnected: () => {
            set((state) => ({
                connected: true,
                closed: false,
                connecting: false,
                supported: true,
                attemptCount: 0,
                maxAttemptsReached: false,
                shouldPoll: state.isPollingTest, // keep as true if polling test still active
            }));
        },
        onConnecting: () => {
            set((state) => ({
                connecting: true,
                attemptCount: state.attemptCount + 1,
            }));
        },
        onClosed: (event: any) => {
            set({ connected: false, connecting: false, closed: true, shouldPoll: true });
        },
        onError: (error: any) => {
            set((state) => {
                // Assuming that if it errored while attempt count has reached max retry count it will not retry any more
                const maxAttemptsReached = state.attemptCount >= maxWebsocketRetryCount;
                return {
                    maxAttemptsReached,
                    supported: maxAttemptsReached ? false : state.supported, // set supported to false if max attempts reached
                };
            });
        },

        // Testing helpers
        isPollingTest: false,
        togglePollingTest: () => {
            set((state) => {
                const newState = !state.isPollingTest;
                return {
                    isPollingTest: newState,
                    shouldPoll: newState || state.closed, // keep as true while connection is still closed
                };
            });
        },
    }));

export const useWebsocketStatus = createWebsocketStore();
