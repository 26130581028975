import { useAbility } from '../auth';
import { appPaths } from '../routeConfig';

export function useHomeRoute() {
    const ability = useAbility();

    let route = appPaths.home;
    let isRedirect = false;

    //Redirect Logic
    if (!ability.can('read', 'trade')) {
        isRedirect = true;
        if (ability.can('read', 'termsheetreview')) {
            route = appPaths.reviews;
        } else {
            route = appPaths.myCompany;
        }
    }

    return {
        route,
        isRedirect,
    };
}
