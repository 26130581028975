import React from 'react';
import { getEnvironmentVariable } from '../../utils/env';
import { LoaderWithMessage, LoaderWithMessageProps } from '@app/components';

const AppInitialisingLoader = ({ message, ...rest }: LoaderWithMessageProps) => {
    let loadingMessages = [];
    try {
        loadingMessages = JSON.parse(getEnvironmentVariable('VITE_LOADING_MESSAGES') || '[]');
    } catch (e) {}
    const randomMessage = loadingMessages?.length
        ? loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
        : 'Initialising';

    return <LoaderWithMessage message={message ?? randomMessage} {...rest} />;
};

export default AppInitialisingLoader;
