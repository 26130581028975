export default {
    bid: {
        base: 'Bid',
        submittor: 'Bidder',
        awarded: 'Awarded $t(activities.bid.base)',
        withdrawn: 'Withdrawn $t(activities.bid.base)',
        expired: 'Expired $t(activities.bid.base)',
        draft: 'Draft $t(activities.bid.base)',
    },
    offer: {
        base: 'Offer',
        submittor: 'Offeror',
        awarded: 'Awarded $t(activities.offer.base)',
        withdrawn: 'Withdrawn $t(activities.offer.base)',
        expired: 'Expired $t(activities.offer.base)',
        draft: 'Draft $t(activities.offer.base)',
    },
    dealTerms: {
        base: 'Deal Terms',
        submittor: 'Dealer',
        awarded: 'Confirmed Deal',
        withdrawn: 'Withdrawn Deal',
        expired: 'Expired Deal',
        draft: 'Draft Deal',
    },
    baseterm: {
        base: 'Base Terms',
    },
    termsheet: {
        base: 'Term Sheet',
        privateDraft: 'Working Draft',
        draft: 'Draft $t(activities.termsheet.base)',
    },
};
