import {
    assign,
    clone,
    cloneDeep,
    filter,
    find,
    findIndex,
    forEach,
    isEqual,
    isString,
    map,
    remove,
} from 'lodash';
import {
    ClientTrade,
    ClientTradeCompany,
    ClientTermsheet,
    PartialClientTermsheetTerm,
    ClientTermsheetTerm,
    Termsheet,
} from '../trade-types';
import { getDebugger } from '@app/components';
import { transformEntityLink } from '../../../utils/graphql';
import { TransactionType, TermsheetType, TradeType } from '../../../gql/generated/types';
import { v4 as uuidv4 } from 'uuid';
import { getGlxDefaultTermsheet } from '../../../components/TermSheet/glx-default-termsheet';

const termsheetDebugger = getDebugger(`TradeTermSheet`);

export type TradeTypeSubset = Pick<ClientTrade, 'tradeType' | 'transactionType'>;

export function getDefaultTermsheetTemplate(): ClientTermsheet {
    return getGlxDefaultTermsheet();
}

export function getDefaultTermValues(term?: PartialClientTermsheetTerm) {
    if (term?.type === 'TABLE') {
        return {
            ...term,
            type: 'TABLE' as const,
            value: term?.value ?? {
                rows: [],
            },
        };
    } else {
        // otherwise TEXT (also the default)
        return {
            ...term,
            type: 'TEXT' as const,
            value: term?.value ?? '',
        };
    }
}

export function getTermsheetRow(
    givenOverrideTerms?: PartialClientTermsheetTerm,
): ClientTermsheetTerm {
    const overrides = getDefaultTermValues(givenOverrideTerms); // fix given overrides in case they only gave a partial type e.g. just { type: "TABLE" } - we need to give it the correct default value

    return {
        label: '',
        scope: 'ALL',
        fixed: false,
        locked: false,
        ...overrides,
        uuid: givenOverrideTerms?.uuid ?? uuidv4(), // try keep existing uuid
    };
}

// Adjust the fixed trade terms according to the trade type
// Currently assumes the fixed terms are just text fields
export function getAdjustedFixedTermsForTrade(
    existingTerms: ClientTermsheetTerm[],
    tradeTypeData: TradeTypeSubset,
    companyName?: string | null,
) {
    const terms = cloneDeep(existingTerms ?? []);
    const previousFixedTerms = filter(terms, { fixed: true });

    //Try find and replace the existing term, otherwise add it to the front of the term list
    function overrideOrAdd(
        terms: ClientTermsheetTerm[],
        id: string,
        givenValues: ClientTermsheetTerm,
    ) {
        const newValues: ClientTermsheetTerm = {
            ...givenValues,
            fixed: true,
            required: givenValues.scope === 'ALL',
            id: id,
        };
        const matchingIndex = findIndex(terms, { id, fixed: true });
        if (matchingIndex >= 0) {
            const combinedValues = {
                ...newValues,
                label: terms[matchingIndex].label,
                locked: terms[matchingIndex].locked,
            };
            terms.splice(matchingIndex, 1, combinedValues);
        } else {
            terms.unshift(newValues);
        }
    }

    // Seller/Buyer rows
    if (tradeTypeData.transactionType === TransactionType.OfferToSell) {
        //Buyer row
        overrideOrAdd(
            terms,
            'buyer',
            getTermsheetRow({
                label: 'Buyer',
                value: '',
                scope: 'TBD',
                type: 'TEXT',
            }),
        );
        //Seller row
        overrideOrAdd(
            terms,
            'seller',
            getTermsheetRow({
                type: 'TEXT',
                label: 'Seller',
                value:
                    (find(previousFixedTerms, { scope: 'ALL' })?.value as string) ??
                    companyName ??
                    '',
                scope: 'ALL',
            }),
        );
    } else if (tradeTypeData.transactionType === TransactionType.RequestToPurchase) {
        //Buyer row
        overrideOrAdd(
            terms,
            'buyer',
            getTermsheetRow({
                type: 'TEXT',
                label: 'Buyer',
                value:
                    (find(previousFixedTerms, { scope: 'ALL' })?.value as string) ??
                    companyName ??
                    '',
                scope: 'ALL',
            }),
        );
        //Seller row
        overrideOrAdd(
            terms,
            'seller',
            getTermsheetRow({
                type: 'TEXT',
                label: 'Seller',
                value: '',
                scope: 'TBD',
            }),
        );
    }

    // Auction price row
    if (tradeTypeData.tradeType === TradeType.Auction) {
        const priceRow = find(terms, { fixed: true, id: 'auctionprice' });
        if (!priceRow) {
            // No price row found, so we should add it to the top
            terms.unshift(
                getTermsheetRow({
                    type: 'TEXT',
                    label: 'Price',
                    scope: 'TBD',
                    id: 'auctionprice',
                    fixed: true,
                }),
            );
        }
    } else {
        // Not an auction? remove the price row
        remove(terms, { id: 'auctionprice', fixed: true });
    }

    return terms;
}

export function getTermsheetTemplateToApply(
    baseTermSheet: ClientTermsheet,
    tradeTypeData: TradeTypeSubset,
    termsheetToApply?: ClientTermsheet,
    companyName?: string | null,
) {
    const existingTemplate = cloneDeep(baseTermSheet);
    if (termsheetToApply) {
        const newTemplate = cloneDeep(termsheetToApply);

        if (newTemplate.id) {
            newTemplate.parent = clone(newTemplate); //record the "parent" termsheet e.g. the template used for the termsheet
        }
        newTemplate.id = existingTemplate?.id; //take the ID from the existing template if it is present, don't use the ID of the template
        newTemplate.name = null; //don't need to copy the name as they need to be unique - server will assign one upon submission
        newTemplate.termsheetType = existingTemplate?.termsheetType;

        // Adjust the fixed terms so they're correct for the current trade
        newTemplate.terms = getAdjustedFixedTermsForTrade(
            newTemplate.terms,
            tradeTypeData,
            companyName,
        );

        // Ensure each row has the appropriate fields, etc
        newTemplate.terms = map(newTemplate.terms, (term) => {
            if (tradeTypeData?.tradeType == TradeType.DealConfirmation) {
                return getTermsheetRow({
                    ...term,
                    locked: false,
                });
            }
            return getTermsheetRow(term);
        });

        return newTemplate;
    }
    return existingTemplate;
}

export function createTermSheetForCompany(
    companyRecord: ClientTradeCompany,
    template?: ClientTermsheet,
): ClientTermsheet {
    const termsheet = cloneDeep(template) ?? <ClientTermsheet>{};
    const previousTermSheet = companyRecord.baseTermsheets?.[0];
    termsheet.id = previousTermSheet?.id ?? null; //maintain the previous termsheet ID if possible
    //Not sure how important this is as the server would probably just generate a new one - regardless, will try keep existing name
    termsheet.name = previousTermSheet?.name ? previousTermSheet?.name : null;

    forEach(companyRecord.specificTerms, function (term) {
        const templateMatch = find(termsheet.terms, {
            uuid: term.uuid,
        });
        if (templateMatch) {
            assign(templateMatch, term); //override with the companies specific terms
        }
    });

    //Assign company to appropriate seller/buyer row
    const sellerBuyerTerm = find(termsheet.terms, (term) => {
        return (
            term.scope === 'TBD' && //find the row that's marked as TBD and assume it is set correctly
            (term.id === 'seller' || term.id === 'buyer')
        );
    }) as ClientTermsheetTerm | undefined;
    if (sellerBuyerTerm) {
        assign(sellerBuyerTerm, {
            scope: 'COMPANY',
            value: companyRecord.company.name,
        });
    }

    termsheetDebugger('Created Term Sheet for company', {
        companyRecord,
        template,
        termsheet,
    });
    return termsheet;
}

//Returns the company specific rows from the termsheet template
//If existing company terms are given, will attempt to retain the values from those terms
export function extractCompanyTerms(
    baseTermsheet?: ClientTermsheet,
    existingCompanyTerms?: ClientTermsheetTerm[],
    previousBaseTermsheet?: ClientTermsheet,
): ClientTermsheetTerm[] {
    const existingTerms = cloneDeep(existingCompanyTerms ?? []),
        newTerms = filter(cloneDeep(baseTermsheet?.terms), (term) => {
            return term.scope === 'COMPANY' && term.fixed !== true;
        });
    forEach(newTerms, function (term) {
        //Try retain the value from the previous terms (just matching by label)
        const existingMatch = find(existingTerms, { label: term.label });
        const previousGeneralValue = find(previousBaseTermsheet?.terms, {
            label: term.label,
        });
        //Retain existing value only if it was different from the previous general terms
        if (existingMatch && !isEqual(previousGeneralValue?.value, existingMatch.value)) {
            term.value = existingMatch.value;
        }
    });
    termsheetDebugger('Extracting company terms', {
        baseTermsheet,
        previousBaseTermsheet,
        existingCompanyTerms,
        newTerms,
    });
    return newTerms;
}

export function setFixedRows(
    termSheet: ClientTermsheet,
    tradeTypeData: TradeTypeSubset,
    companyName?: string | null,
) {
    if (termSheet?.terms) {
        termSheet.terms = getAdjustedFixedTermsForTrade(
            termSheet.terms,
            tradeTypeData,
            companyName,
        );
    }
    return termSheet;
}

/**
 * Converts the termsheet into a submittable form for the server.
 * If a base termsheet is given, will only keep the terms that are different from the base termsheet
 */
export function transformTermsheetForServer(
    termsheet: ClientTermsheet,
    termsheetType = TermsheetType.Base,
    baseTermsheet?: ClientTermsheet,
): Termsheet {
    let termsCopy = cloneDeep(termsheet?.terms ?? []);

    // Base termsheet was given? Assume we only want to keep the terms that are different.
    if (baseTermsheet) {
        termsCopy = filter(termsCopy, (term) => {
            const baseMatch = find(baseTermsheet?.terms, {
                label: term.label,
                scope: term.scope,
                value: term.value,
            });
            return !baseMatch;
        });
    }

    const outputTermsheet = {
        ...termsheet,
        terms: convertTermsForServer(termsCopy),
        termsheetType,
    };

    transformEntityLink(outputTermsheet, 'parent');

    return outputTermsheet;
}

// For now just sending a JSON string to the server. I think it also supports receiving json object? e.g. don't even need to stringify, but that's what we've been doing everywhere so just going to keep it consistent
export function convertTermsForServer(terms: ClientTermsheet['terms']) {
    terms = terms.map((t: any) => {
        t.label = t.label.trim();
        if (isString(t.value)) {
            t.value = t.value.trim();
        }
        return t;
    });
    return JSON.stringify(terms);
}

export function transformTermsheetForClient(
    givenTermsheet: Termsheet | null | undefined,
): ClientTermsheet {
    const termsheet = givenTermsheet ?? {};

    return {
        ...termsheet,
        parent: termsheet.parent ? transformTermsheetForClient(termsheet.parent) : undefined,
        // I think the API just returns whatever the client sent it - in most cases that's a JSON string.
        terms: termsheet.terms
            ? isString(termsheet.terms)
                ? JSON.parse(termsheet.terms)
                : termsheet.terms
            : [],
    };
}
