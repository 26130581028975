import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, useRadio, useRadioGroup } from '@chakra-ui/react';
import {
    forwardRef,
    omitThemingProps,
    StylesProvider,
    useMultiStyleConfig,
    useStyles,
} from '@chakra-ui/system';
import { dataAttr } from '@chakra-ui/utils';

const RadioGroup = forwardRef(function RadioGroup(props, ref) {
    const styles = useMultiStyleConfig('GlxRadioButton', props); //"GlxRadioButton" to avoid clash with chakra
    const ownProps = omitThemingProps(props);
    const {
        options = [],
        isReadOnly,
        isDisabled,
        isRequired,
        getOptionValue = (option) => option.value ?? option,
        getOptionLabel = (option) => option.label ?? option,
        prefix,
        getOutputValue,
        onChange,
        ...rest
    } = ownProps;

    // onChange from radioGroup always returns a string, so if a getOutputValue is given we will call that with the value to transform it e.g. for booleans
    const onChangeWrapper = useCallback(
        (nextValue) =>
            getOutputValue ? onChange?.(getOutputValue(nextValue)) : onChange?.(nextValue),
        [onChange, getOutputValue],
    );

    const radioGroupOptions = useMemo(
        () => ({
            ...rest,
            onChange: onChangeWrapper,
        }),
        [onChange, getOutputValue, rest],
    );

    const { htmlProps, getRootProps, getRadioProps, setValue } = useRadioGroup(radioGroupOptions);

    const group = getRootProps(htmlProps, ref);

    return (
        <StylesProvider value={styles}>
            <Box __css={styles.radioGroup} {...group}>
                {prefix}
                {options.map((option, index) => {
                    const radio = getRadioProps({
                        value: getOptionValue(option),
                    });
                    return (
                        <RadioOption
                            key={getOptionValue(option)}
                            {...radio}
                            isFirst={!prefix && index === 0}
                            isLast={index === options.length - 1}
                            isReadOnly={isReadOnly}
                            isDisabled={isDisabled}
                            isRequired={isRequired}
                        >
                            {getOptionLabel(option)}
                        </RadioOption>
                    );
                })}
            </Box>
        </StylesProvider>
    );
});
//Also see chakra radio props
RadioGroup.propTypes = {
    options: PropTypes.array,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    prefix: PropTypes.node, //element to appear before the radio options e.g. useful for a label
};

const RadioOption = forwardRef(function RadioOption(
    { isFirst, isLast, children, ...radioProps },
    ref,
) {
    const styles = useStyles();
    const { getInputProps, getCheckboxProps, state } = useRadio(radioProps);

    const input = getInputProps({}, ref);
    const checkbox = getCheckboxProps();
    const { isChecked, isFocused, isHovered } = state;
    const isActive = !(state.isReadOnly || state.isDisabled);

    const styleGetterProps = {
        isFirst,
        isLast,
        ...state,
    };

    const optionWrapperStyles = styles.radioOptionWrapper(styleGetterProps);
    const optionStyles = styles.radioOption(styleGetterProps);

    const wrapperAttributes = {
        'data-hover': dataAttr(isHovered),
        'data-focus': dataAttr(isFocused),
        'data-checked': dataAttr(isChecked),
    };

    return (
        <Box as="label" __css={optionWrapperStyles} {...wrapperAttributes}>
            <input {...input} />
            <Box {...checkbox} __css={optionStyles}>
                {children}
            </Box>
        </Box>
    );
});
RadioOption.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    children: PropTypes.node,
};

export { RadioGroup, RadioOption };
