import React, { useEffect } from 'react';
import { useFormControl } from './use-form-control';
import { chakra, forwardRef, omitThemingProps, useStyles } from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import NumberFormat from 'react-number-format';
import { useFormControlContext } from './FormControl';
import { Box } from '@chakra-ui/react';
import { haveInputValue } from '@app/lib';

/**
 * Input
 *
 * Element that allows users enter single valued data.
 */
const FormControlInput = forwardRef(function Input(props, ref) {
    const styles = useStyles();
    const ownProps = omitThemingProps(props);

    //Extract our props which don't need to get passed on
    const { getViewDisplayValue = (formattedValue, rawValue) => formattedValue, ...restProps } =
        ownProps;

    const input = useFormControl(restProps);
    const _className = cx('glx-input', props.className);

    const context = useFormControlContext();

    const displayAsText =
        !context.variant && //only doing this for the default variant
        input.readOnly && //
        (!props?.type ||
            props?.type === 'text' ||
            //for now (until we have a better datepicker component) we won't display valid dates as text so that the displayed date format stays in the browser format
            //i.e. the format in the input might be dd/mm/yyyy but the output value is yyyy/mm/dd so we would end up showing a different value
            ((props?.type === 'date' || props?.type === 'datetime-local') &&
                !haveInputValue(input.value)));

    useEffect(() => {
        context.setInputType(props?.type);
        context.setInputRef(ref);
    }, [context.setInputType, context.setInputRef]);

    if (displayAsText) {
        return (
            <Box __css={styles.input} className={_className} {...input}>
                {getViewDisplayValue(input.value || <>&mdash;</>, input.value)}
            </Box>
        );
    }
    return <chakra.input __css={styles.input} ref={ref} {...input} className={_className} />;
});

/**
 * Number Input
 *
 * Element that allows users to enter numbers.
 * Uses react-number-format under the hood, so any props that supports should be usable on this component
 * By default it will add the separator for thousands
 * Needs to be implemented using a Controller for react-hook-form
 */
const FormControlInputNumber = forwardRef(function InputNumber(props, ref) {
    const styles = useStyles();

    //Extract our props which don't need to get passed on
    const {
        numberOptions,
        getViewDisplayValue = (formattedValue, rawValue) => formattedValue,
        asString = false,
        ...restProps
    } = props;

    const ownProps = omitThemingProps(restProps);
    const input = useFormControl(ownProps);
    const { onChange, ...restInputProps } = input; //take onChange out as we want to use onValueChange instead
    const _className = cx('glx-input', props.className);

    const context = useFormControlContext();

    const displayAsText =
        !context.variant && //only doing this for the default variant
        input.readOnly;

    useEffect(() => {
        context.setInputType('number');
        context.setInputRef(ref);
    }, [context.setInputType, context.setInputRef]);

    if (displayAsText) {
        return (
            <Box
                as={NumberFormat}
                displayType="text"
                value={input.value}
                thousandSeparator={true}
                {...numberOptions}
                renderText={(formattedValue) => {
                    return (
                        <Box __css={styles.input} className={_className} {...input}>
                            {getViewDisplayValue(formattedValue || <>&mdash;</>, input.value)}
                        </Box>
                    );
                }}
            />
        );
    }
    return (
        <chakra.input
            as={NumberFormat}
            getInputRef={ref}
            thousandSeparator={true}
            __css={styles.input}
            onValueChange={(values) => {
                //need to use onValueChange instead of onChange
                const { floatValue, value } = values; //use the "value" (1234) instead of "formattedValue" (1,234)
                const outputValue = (asString ? value : floatValue) ?? null;
                onChange?.(outputValue);
            }}
            {...numberOptions}
            {...restInputProps}
            className={_className}
            // Disabled due to issues outlined in LAZ-2069
            //     // Show number input on mobile - https://github.com/s-yadav/react-number-format/issues/189
            //     inputMode="decimal"
            //     pattern="[0-9]*"
        />
    );
});

export { FormControlInput, FormControlInputNumber };
