import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useBoolean, useId } from '@chakra-ui/hooks';
import { chakra, forwardRef, omitThemingProps, StylesProvider } from '@chakra-ui/system';
import { Box, useMultiStyleConfig, useStyles } from '@chakra-ui/react';
import { dataAttr, cx } from '@chakra-ui/utils';
import { createContext } from '@chakra-ui/react-utils';
import { useFormContext } from 'react-hook-form';
import { useInputGroupContext } from '../InputGroup';
import { get } from 'lodash';

const [FormControlProvider, useFormControlContext] = createContext({
    strict: false,
    name: 'GlxFormControlContext',
});

//Check form and infer what we can from it's context
function useContextFromForm(name, control) {
    const formContext = useFormContext();
    // Will fall back to using the form state from the control if it's given (and no form context is found)
    const { errors, dirtyFields, touchedFields, isSubmitted } =
        formContext?.formState ?? control?._formState ?? {};
    return {
        isInvalid: !!get(errors, name),
        isDirty: !!get(dirtyFields, name),
        isTouched: !!get(touchedFields, name),
        isSubmitted: !!isSubmitted,
        errors: get(errors, name),
    };
}

function useFormControlProvider(props) {
    //Extract the props that are for the form control context
    const {
        id: idProp,
        name,
        isRequired,
        isInvalid,
        isDisabled,
        isLoading,
        isReadOnly,
        isDirty,
        isTouched,
        isSubmitted,
        onChange,
        errors,
        showContainerStyles, //removing from props so it's not passed onto the dom element
        variant, //style variant - add it to context so child components can react
        inputRef: givenInputRef,
        inputType: givenInputType,
        control,
        ...htmlProps //remaining props that will be set on the form control wrapper
    } = props;

    // Generate all the required ids
    const id = useId(idProp, 'field');
    const labelId = `${id}-label`;

    //Let's keep track of the reference to the input
    const [inputRef, setInputRef] = useState(givenInputRef);

    //Let the inputs tell the form control what type they are
    const [inputType, setInputType] = useState(givenInputType);

    // Let's keep track of when we focus the form element (e.g, `input`)
    const [isFocused, setFocus] = useBoolean();

    const contextFromForm = useContextFromForm(name, control);
    const contextFromInputGroup = useInputGroupContext();

    const isControlReadOnly = isReadOnly ?? contextFromInputGroup?.isReadOnly;
    const isControlDisabled =
        !isControlReadOnly && (isDisabled ?? contextFromInputGroup?.isDisabled); //if set to read only, no point also showing as disabled

    return {
        isRequired: !!isRequired,
        isInvalid: !!isInvalid || contextFromForm?.isInvalid,
        isLoading: !!isLoading,
        isReadOnly: isControlReadOnly,
        isDisabled: isControlDisabled,
        isFocused: !!isFocused,
        isDirty: !!isDirty || contextFromForm?.isDirty,
        isTouched: !!isTouched || contextFromForm?.isTouched,
        isSubmitted: !!isSubmitted || contextFromForm?.isSubmitted,
        errors: errors || contextFromForm?.errors,
        onFocus: setFocus.on,
        onBlur: setFocus.off,
        onChange,
        inputRef: inputRef,
        setInputRef: setInputRef,
        inputType: inputType,
        setInputType: setInputType,
        id,
        labelId,
        name,
        variant,
        htmlProps: omitThemingProps(htmlProps), //strips props such as "styleConfig", "size", "variant", "colorScheme" as they're only needed for useMultiStyleConfig
    };
}

function useFormControlProps(context) {
    return {
        'data-focus': dataAttr(context?.isFocused),
        'data-disabled': dataAttr(context?.isDisabled),
        'data-invalid': dataAttr(context?.isInvalid),
        'data-loading': dataAttr(context?.isLoading),
        'data-readonly': dataAttr(context?.isReadOnly),
    };
}

const FormControlIconWrapper = ({ children, position, showIcon = true }) => {
    const styles = useStyles();
    const iconStyleKey = position === 'left' ? 'iconLeft' : 'iconRight';
    const iconStyles = styles[iconStyleKey];
    if (!showIcon) {
        return null;
    }
    return <Box __css={iconStyles}>{children}</Box>;
};

FormControlIconWrapper.propTypes = {
    children: PropTypes.node,
    position: PropTypes.oneOf(['left', 'right']),
    showIcon: PropTypes.bool,
};

const FormControlContainer = forwardRef(function FormControlContainer(props, ref) {
    const { children, leftIcon, rightIcon, showIcon, ...rest } = props;
    const styles = useStyles();

    return (
        <chakra.div role="group" ref={ref} __css={styles.formControl} {...rest}>
            {leftIcon && (
                <FormControlIconWrapper showIcon={showIcon} position="left">
                    {leftIcon}
                </FormControlIconWrapper>
            )}
            {children}
            {rightIcon && (
                <FormControlIconWrapper showIcon={showIcon} position="right">
                    {rightIcon}
                </FormControlIconWrapper>
            )}
        </chakra.div>
    );
});

const FormControl = forwardRef(function FormControl(props, ref) {
    const { htmlProps, ...context } = useFormControlProvider(props);
    const _className = cx('glx-form-control', props.className);
    const wrapperProps = useFormControlProps(context);
    const styles = useMultiStyleConfig('GlxFormControl', { ...props }); //"GlxFormControl" to avoid clash with chakra
    const handleWrapperClick = () => {
        context?.inputRef?.current?.focus?.(); //attempt to focus the input if we can
    };

    return (
        <FormControlProvider value={context}>
            <StylesProvider value={styles}>
                <FormControlContainer
                    ref={ref}
                    {...htmlProps}
                    {...wrapperProps}
                    className={_className}
                    onClick={handleWrapperClick}
                />
            </StylesProvider>
        </FormControlProvider>
    );
});

FormControl.defaultProps = {
    showContainerStyles: true,
};

//TODO: Convert this file to typescript so we can just use FormControlProps
FormControl.propTypes = {
    /**
     * The custom `id` to use for the form control.
     * - This will be passed directly to the form element (e.g, Input).
     * - The form label will have an id of : `form-label-${id}`
     */
    id: PropTypes.string,
    //Name to be assigned to the input
    name: PropTypes.string,
    //Label to be displayed for the input
    label: PropTypes.string,
    //Whether or not the label is hidden
    hideLabel: PropTypes.bool,
    //Errors for this input
    errors: PropTypes.object,
    //If true, the form control will required.
    isRequired: PropTypes.bool,
    // If true, the form control will be disabled
    isDisabled: PropTypes.bool,
    //If true, the form control will be readonly
    isReadOnly: PropTypes.bool,
    //If true, the form control will be invalid
    isInvalid: PropTypes.bool,
    //If true, the form control will be in its loading state
    isLoading: PropTypes.bool,
    //When true, the form value has been changed
    isDirty: PropTypes.bool,
    //When true, the form element has been touched/interacted with
    isTouched: PropTypes.bool,
    //When true, the form has been submitted (or an attempt has been made)
    isSubmitted: PropTypes.bool,
    //Props to be spread onto the label, ideally want to add proper support for the prop here though
    labelProps: PropTypes.object,
    //Icon to show on the left of the input
    leftIcon: PropTypes.node,
    //Icon to show on the right of the input
    rightIcon: PropTypes.node,
    //Whether or not the icon should be visible
    showIcon: PropTypes.bool,
    //Whether or not the form contorl container styles should be visible (if false, will just be blank)
    showContainerStyles: PropTypes.bool,
    //Show a tooltip for form control is it has a help text
    helpText: PropTypes.object,
    inputRef: PropTypes.any,
    inputType: PropTypes.string,
};

export { FormControl, useFormControlContext };
