import { differenceInMilliseconds } from 'date-fns';
import Keycloak from 'keycloak-js';
import { getEnvironmentVariable } from '../env';

let refreshPromise: null | Keycloak.KeycloakPromise<boolean, boolean> = null;

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak = Keycloak({
    url: getEnvironmentVariable('VITE_KEYCLOAK_URL'),
    realm: getEnvironmentVariable('VITE_KEYCLOAK_REALM'),
    clientId: getEnvironmentVariable('VITE_KEYCLOAK_CLIENT_ID'),
});

// Refresh the keycloak token
export async function refreshToken(minValidity = 600) {
    if (refreshPromise) {
        return refreshPromise;
    }

    refreshPromise = keycloak.updateToken(minValidity);
    refreshPromise.then((value: boolean) => {
        refreshPromise = null;
    });
    return refreshPromise;
}

export default keycloak;
