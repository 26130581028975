import { useToast } from '../toasts/useToast';
import { SubmitErrorHandler, ErrorOption } from 'react-hook-form';
import { capitalize } from 'lodash';

interface ErrorGroup {
    keys: string[];
    errors: Record<string, ErrorOption>;
}

type LabelMap = Record<string, string>;

/**
 * Create onInvalid
 *
 * @description
 * For use as or in the second param of react-hook-form's handleSubmit
 * to notify the user of any validation errors
 *
 * @param toast - Return value of useToast
 * @param labelMap - Map of field labels to their associated label.
 * Including a labelMap improves readability of combined required messages.
 * @param errorTitle - Title for the error, defaults to 'Unable to save'
 */
export const createOnInvalid = (
    toast: ReturnType<typeof useToast>,
    labelMap: LabelMap = {},
    errorTitle = 'Unable to save',
) => {
    // It doesn't matter what the shape of the form data is so we type it as any
    const onInvalid: SubmitErrorHandler<any> = (errors: Record<string, ErrorOption>) => {
        const keys = Object.keys(errors);

        // Get the error message
        let message = undefined;
        if (keys.length !== 0) {
            // Build the combined error message if errors
            message = getCombinedErrorMessages({ keys, errors }, labelMap);
        }

        toast({ title: errorTitle, description: message, status: 'error' });
    };
    return onInvalid;
};

/**
 * Combines error messages together
 */
const getCombinedErrorMessages = (errorGroup: ErrorGroup, labelMap: LabelMap) => {
    const { keys, errors } = errorGroup;

    const requiredErrorGroup: ErrorGroup = {
        keys: [],
        errors: {},
    };

    const messages = keys
        .reduce((str, key) => {
            const error = errors[key];
            // Required error messages with a label should be handled on their own
            if (error.type === 'required' && labelMap[key]) {
                requiredErrorGroup.keys.push(key);
                requiredErrorGroup.errors[key] = error;
            } else {
                str += `${error.message}. `;
            }
            return str;
        }, '')
        .trim();

    if (messages == '') {
        return getLabeledRequiredMessage(requiredErrorGroup, labelMap);
    } else if (requiredErrorGroup.keys.length === 0) {
        return messages;
    } else {
        return `${getLabeledRequiredMessage(requiredErrorGroup, labelMap)}. ${messages}`;
    }
};

/**
 * Combines required error messages together
 * We use the message when only one with map so you can have singular and sentence versions of messages
 */
const getLabeledRequiredMessage = (requiredErrorGroup: ErrorGroup, labelMap: LabelMap) => {
    const requiredSuffix = ' are required';
    const { errors, keys } = requiredErrorGroup;

    // Handle only one required message (use error.message and not label)
    if (keys.length === 1) {
        const key = keys[0];
        const error = errors[key];
        return `${error.message}.`;
    }

    return keys.reduce((str, key, i) => {
        const error = errors[key];
        const label = labelMap[key];

        if (i === 0 && i === keys.length - 2) {
            // If first and second last item
            str += `${capitalize(label)} and `;
        } else if (i === 0) {
            // If first item
            str += `${capitalize(label)}, `;
        } else if (i === keys.length - 2) {
            // If second last item
            str += `${label} and `;
        } else if (i === keys.length - 1) {
            // If last item
            str += `${label}${requiredSuffix}.`;
        } else {
            // If any other item
            str += `${label}, `;
        }
        return str;
    }, '');
};
