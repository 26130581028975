import React, { useEffect } from 'react';
import create, { State, StateSelector } from 'zustand';
import createContext from 'zustand/context';
import { isEqual } from 'lodash';
import { useGetUserQuery, UserDataFragment } from '../../gql/generated/users';

export type UserDataForStore = UserDataFragment | undefined;

export interface UserStoreState extends State {
    user: UserDataForStore;
    setUser: (user: UserDataForStore) => void;
}

export type UserContextData = UserStoreState['user'];

const { Provider, useStore } = createContext<UserStoreState>();

// Provider is exported for testing purposes
export { Provider };

export const useCreateUserStore = (user?: UserContextData) => () =>
    create<UserStoreState>((set, get) => {
        return {
            user,
            setUser: (user: UserDataForStore) => {
                set({ user });
            },
        };
    });

export const useUserStore = <U,>(selector: StateSelector<UserStoreState, U>) =>
    useStore(selector, isEqual);

export function UserProvider({
    children,
    username,
}: {
    children: React.ReactNode;
    username?: string;
}) {
    const createStore = useCreateUserStore();

    return (
        <Provider createStore={createStore}>
            <StoreUpdater username={username} />
            {children}
        </Provider>
    );
}

// For providing your own user object, useful for testing
export function CustomUserProvider({
    children,
    user,
}: {
    children: React.ReactNode;
    user?: UserDataForStore;
}) {
    const createStore = useCreateUserStore(user);

    return <Provider createStore={createStore}>{children}</Provider>;
}

function StoreUpdater({ username }: { username?: string }) {
    const [user, setUser] = useStore((state) => [state.user, state.setUser], isEqual);

    const [result] = useGetUserQuery({
        variables: { query: { username: username } },
        pause: !username,
        requestPolicy: 'network-only',
    });

    const resultUser = result.data?.user;

    useEffect(() => {
        if (!isEqual(resultUser, user)) {
            setUser(resultUser);
        }
    }, [resultUser, user, setUser]);

    return null;
}

// Hook to fetch the current user from the store
export const useUser = () => {
    return useUserStore((state) => state.user);
};
