import { useEffect } from 'react';
import { usePrevious } from '@chakra-ui/hooks';
import { useWebsocketStatus, websocketPollingInterval } from './websocketStore';
import { IntervalCallback, useInterval } from '../hooks/useInterval';
import { useUpdatingRef } from '../hooks/useUpdatingRef';

// Poll a given callback function on a given interval when there is no established websocket connection
export function usePollingFallback(
    callback?: IntervalCallback,
    delay: null | number = websocketPollingInterval,
) {
    const [shouldPoll] = useWebsocketStatus((state) => [state.shouldPoll]);

    const callBackRef = useUpdatingRef(callback);
    const wasPolling = usePrevious(shouldPoll);
    useEffect(() => {
        if (!shouldPoll && wasPolling) {
            // Trigger the callback one last time when no longer polling, just to ensure nothing was missed in the intermediate period
            callBackRef.current?.();
        }
    }, [shouldPoll, wasPolling, callBackRef]);

    // Passing delay as null if we don't want the polling interval to be active - note that the caller of this hook could also pass delay as null to prevent polling
    return useInterval(callback, shouldPoll ? delay : null);
}
