import React, { useMemo, useRef, useState } from 'react';
import {
    NotificationBody,
    NotificationItem,
    NotificationItemProps,
    NotificationNavItem,
    NotificationNavItemProps,
    NotificationsMenu,
    RemoveBodyScroll,
} from '@app/components';
import {
    NotificationDataFragment,
    useGetNotificationsQuery,
    useGetUnseenNotificationsCountQuery,
    useMarkAllReadMutation,
    useMarkAllSeenMutation,
    useMarkReadMutation,
    useOnNotificationSubscription,
} from '../../gql/generated/notifications';
import { findIndex, orderBy } from 'lodash';
import { isMobile } from 'react-device-detect';
import { Link } from '../../utils/pages/Link';
import { getNotificationUrl } from './getNotificationUrl';
import { Flex, Icon } from '@chakra-ui/react';
import { MdChevronRight } from 'react-icons/md';
import { startOfDay, sub } from 'date-fns';

export function HeaderNotifications({
    onOpenChange,
    ...rest
}: Omit<NotificationNavItemProps, 'unseenCount'>) {
    const [isOpen, setIsOpen] = useState(false);

    const [
        { data: unseenCountResponse, fetching: loadingUnseenNotificationCount },
        refetchUnreadCount,
    ] = useGetUnseenNotificationsCountQuery();
    const unseenCount = unseenCountResponse?.unseenCount;

    const minNotificationDate = useMemo(() => startOfDay(sub(new Date(), { days: 30 })), []);
    const [
        { data: notificationsResponse, fetching: loadingNotifications },
        refetchNotificationResponse,
    ] = useGetNotificationsQuery({
        variables: {
            sort: { createdAt: 'DESC' },
            query: {
                createdAt: {
                    _gte: minNotificationDate,
                },
            },
        },
    });

    // Notification Subscriber
    useOnNotificationSubscription({}, (response) => {
        refetchUnreadCount();
        return response.data;
    });

    const notificationList = notificationsResponse?.notifications?.data ?? [];
    const sortedNotifications = orderBy(notificationList, ['createdAt'], ['desc']);
    const isLoading = !notificationList.length && loadingNotifications;

    const [markAllReadState, markAllRead] = useMarkAllReadMutation();
    const [markAllSeenState, markAllSeen] = useMarkAllSeenMutation();
    const [markAsReadState, markAsRead] = useMarkReadMutation();

    const onMarkAllAsRead = () => {
        markAllRead();
    };

    const onMarkAsRead = (notification: NotificationDataFragment) => {
        if (!notification.read) {
            markAsRead({ ids: [notification.id] });
        }
    };

    //TODO: Unsure how this will work with pagination - might need a specific query to get this count?
    const haveUnread = findIndex(notificationList, { read: false }) >= 0;

    const onOpenWrapper = (wasOpened: boolean) => {
        setIsOpen(wasOpened);
        onOpenChange?.(wasOpened);
        if (wasOpened) {
            refetchNotificationResponse();
            onMarkAllAsSeen();
        }
    };

    // Will keep track of the most recent "seen" notification to avoid unecessary calls
    const [mostRecentSeenId, setMostRecentSeenId] = useState<number | null>(null);
    const onMarkAllAsSeen = () => {
        const newestNotificationId = sortedNotifications.length ? sortedNotifications[0].id : null;
        if (mostRecentSeenId !== newestNotificationId) {
            setMostRecentSeenId(newestNotificationId);
            markAllSeen().then(refetchUnreadCount);
        }
    };

    const listRef = useRef<HTMLDivElement | null>(null);
    const lockScroll = isMobile && isOpen;

    return (
        <NotificationNavItem
            isOpen={isOpen}
            unseenCount={unseenCount}
            {...rest}
            onOpenChange={onOpenWrapper}
        >
            <RemoveBodyScroll enabled={lockScroll} scrollableElementRef={listRef}>
                <NotificationsMenu
                    notifications={sortedNotifications}
                    isLoading={isLoading}
                    haveUnread={haveUnread}
                    markAllAsRead={onMarkAllAsRead}
                    listRef={listRef}
                >
                    {(notifications) =>
                        notifications?.map((notification, index) => {
                            const notificationUrl = getNotificationUrl(notification.data);
                            const onClick = () => {
                                onMarkAsRead(notification);
                                if (notificationUrl) {
                                    setIsOpen(false);
                                }
                            };
                            return (
                                <NotificationItem
                                    key={notification.id}
                                    as={notificationUrl ? Link : undefined}
                                    preload={notificationUrl ? false : undefined}
                                    to={notificationUrl ?? ''}
                                    isLast={index === notifications.length - 1}
                                    isRead={notification.read}
                                    isClickable={!!notificationUrl || !notification.read}
                                    onClick={onClick}
                                    role="group"
                                >
                                    <Flex alignItems="center">
                                        <NotificationBody
                                            title={notification.title ?? ''}
                                            body={notification.body ?? ''}
                                            timestamp={notification.createdAt}
                                            flex="1 1 auto"
                                        />
                                        {notificationUrl && (
                                            <Icon
                                                as={MdChevronRight}
                                                className="link-icon"
                                                fontSize="5xl"
                                                flex="0 0 auto"
                                                paddingX={2}
                                                _groupHover={{
                                                    color: 'primary.500',
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </NotificationItem>
                            );
                        })
                    }
                </NotificationsMenu>
            </RemoveBodyScroll>
        </NotificationNavItem>
    );
}
