import { UseToastOptions } from '@chakra-ui/react';
import { head } from 'lodash';
import { CombinedError } from 'urql';

export function getErrorMessage(
    error: CombinedError,
    fallbackError: string,
    /**
     * If this is true, will use the "message" property on the graphql error.
     * Defaulting to false as this message isn't always a friendly message for the user to see so can opt into using it depending on the contexxt and whether we trust the message will be nice
     */
    useGraphQLErrorMessage = false,
) {
    /**
     * TODO:
     * Sometimes we have custom errors being returned on error.graphQLErrors[0].message, but sometimes that message is some generic graphql / mikroORM error (which we wouldn't want to show)
     * In future we should probably make this more consistent?
     */
    const serverError = head(error?.graphQLErrors);
    let errorToUse = serverError?.message;
    if (!useGraphQLErrorMessage) {
        errorToUse = head<any>(
            (serverError?.extensions?.exception as any)?.response?.objectOrError,
        )?.errorMessage;
    }
    const otherErrors = (serverError?.extensions as any)?.response?.message;
    return errorToUse ?? otherErrors ?? fallbackError;
}

export function getServerErrorToast(
    error: CombinedError,
    fallbackError: string,
    // See use for getErrorMessage
    useGraphQLErrorMessage = false,
) {
    return getErrorToast(getErrorMessage(error, fallbackError, useGraphQLErrorMessage));
}

export function getErrorToast(errorMessage: string) {
    return {
        status: 'error',
        description: errorMessage,
    } as UseToastOptions;
}
