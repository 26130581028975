import React, { useContext } from 'react';
import { User as BaseUser } from '@app/lib';

// Extending base user to add application specific attributes
export type User = BaseUser & {
    given_name: string;
    family_name: string;
    company_id: number;
};

export type Auth = {
    user?: User;
};

const defaultAuthState: Auth = {
    user: undefined,
};

export const AuthContext = React.createContext(defaultAuthState);

export function useAuth(): Auth {
    return useContext(AuthContext);
}
