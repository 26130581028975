import React, { useEffect } from 'react';
import { BaseControlWrapper, FormControlInput } from '../FormElements';
import {
    FormControlHookFormControllerProps,
    FormControlProps,
} from '../FormElements/FormControl/types';
import { Controller, useFormContext } from 'react-hook-form';
import { BoxProps, ThemingProps, InputProps } from '@chakra-ui/react';

export interface SimpleFormControlProps
    extends FormControlProps,
        FormControlHookFormControllerProps,
        ThemingProps,
        Pick<InputProps, 'maxLength'>,
        Omit<BoxProps, 'onChange' | 'onFocus' | 'onBlur' | 'defaultValue'> {
    //type to be assigned to the input
    type?: string;
}

export function SimpleFormControl(props: SimpleFormControlProps) {
    const {
        value,
        defaultValue,
        placeholder,
        type,
        control,
        rules,
        inputProps,
        getViewDisplayValue,
        maxLength,
        ...wrapperProps
    } = props;
    const { name } = props; //take name separately as we want it included in wrapperProps
    const allInputProps = {
        type,
        placeholder,
        maxLength,
        getViewDisplayValue,
        ...inputProps,
    };

    const formContext = useFormContext();
    const formControl = control ?? formContext?.control; //fall back to control from form context if it's available
    const useController = formControl && name; //Check if we have required properties to use a react-hook-form "Controller" component

    const combinedRules = { maxLength: 255, ...rules };

    return (
        <BaseControlWrapper {...wrapperProps}>
            {useController && (
                <Controller
                    render={({ field }) => <FormControlInput {...field} {...allInputProps} />}
                    name={name as any}
                    control={formControl}
                    rules={combinedRules}
                    defaultValue={defaultValue}
                />
            )}
            {!useController && <FormControlInput value={value} {...allInputProps} />}
        </BaseControlWrapper>
    );
}
