import React from 'react';
import { HeaderAccount } from './HeaderAccount';
import { HeaderNotifications } from './HeaderNotifications';
import {
    createPortal,
    Header as BaseHeader,
    HeaderProps as BaseHeaderProps,
    NavList,
} from '@app/components';
import { HeaderHamburger } from './HeaderHamburger';
import { Flex } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

export interface HeaderProps extends BaseHeaderProps {
    showHamburger?: boolean;
    showAccount?: boolean;
}

export const Header = forwardRef(function Header(
    { showHamburger = false, showAccount = true, ...rest }: HeaderProps,
    ref,
) {
    return (
        <BaseHeader direction="column" {...rest} ref={ref}>
            <Flex alignItems="stretch">
                {showHamburger && <HeaderHamburger marginRight={2} alignSelf="center" />}
                <HeadingContainer width="1px" flex="1 1 auto" paddingY={2} alignSelf="center" />
                <NavList height={16} alignSelf="flex-start">
                    <HeaderNotifications />
                    {showAccount && <HeaderAccount />}
                </NavList>
            </Flex>
            <SubHeadingContainer />
        </BaseHeader>
    );
});

// Create the heading/subheading components
const {
    Container: HeadingContainer,
    Portal: HeadingPortal,
    usePortalStore: useHeadingStore,
} = createPortal();
const {
    Container: SubHeadingContainer,
    Portal: SubHeadingPortal,
    usePortalStore: useSubHeadingStore,
} = createPortal();
export {
    HeadingContainer,
    HeadingPortal,
    SubHeadingContainer,
    SubHeadingPortal,
    useHeadingStore,
    useSubHeadingStore,
};
