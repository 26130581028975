import { theme } from '@app/components';
import { extendTheme } from '@chakra-ui/react';
import GlxFormControl from '../components/FormElements/FormControl/form-control-theme';

const mergedTheme: any = extendTheme(theme, {
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    components: {
        GlxFormControl,
    },
    colors: {
        purchase: {
            300: '#4CB3AB',
        },
        sale: {
            300: '#CA6E79',
        },
    },
});

export default mergedTheme;
