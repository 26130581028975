import React from 'react';
import { Box, Tooltip, Flex, Icon } from '@chakra-ui/react';
import { forwardRef, omitThemingProps, useStyles } from '@chakra-ui/system';
import { cx, dataAttr } from '@chakra-ui/utils';
import { useFormControlContext } from './FormControl';
import { InformationIcon } from '@app/components';
import { FaQuestionCircle } from 'react-icons/fa';

const defaultErrorMessages = {
    required: 'You must provide a value for this input',
    minLength: 'Must be greater than the minimum length',
    maxlength: 'Must be less than the maximum length',
    min: 'Must be greater than the minimum number',
    max: 'Must be less than the maximum number',
};

function useFormControlProps(props) {
    const context = useFormControlContext();
    return {
        ...props,
        'data-focus': dataAttr(context?.isFocused),
        'data-disabled': dataAttr(context?.isDisabled),
        'data-invalid': dataAttr(context?.isInvalid),
        'data-loading': dataAttr(context?.isLoading),
        'data-readonly': dataAttr(context?.isReadOnly),
        id: props.id ?? context?.labelId,
        htmlFor: props.htmlFor ?? context?.id,
    };
}

const FormControlLabel = forwardRef(function FormLabel(passedProps, ref) {
    const props = omitThemingProps(passedProps);
    const styles = useStyles();
    const {
        className,
        children,
        helpText,
        requiredIndicator = <RequiredIndicator />,
        showErrorMessages = true,
        ...rest
    } = props;

    const ownProps = useFormControlProps(rest);
    const context = useFormControlContext();

    const hasErrors = !!(context?.errors && context?.errors?.type);

    const showErrors = showErrorMessages && hasErrors && !context?.isReadOnly;

    const errorMessage =
        context?.errors?.message ?? defaultErrorMessages[context?.errors?.type] ?? 'Invalid';

    // If no label, prefix error message with "Field" so will instead show "Field is required", etc
    const errorPrefix = !children ? 'Field ' : `${children} `;

    const showIsRequired = showErrors && context?.errors?.type === 'required';

    const showIsInvalidMaxLength = showErrors && context?.errors?.type === 'maxLength';

    const showFullErrorMessage = showErrors && !showIsRequired && !showIsInvalidMaxLength;

    const showRequiredIndicator = !hasErrors && !context?.isReadOnly && context?.isRequired; //Don't need to show required indicator if we are showing error

    const showAnything =
        !!children || showIsRequired || showRequiredIndicator || showFullErrorMessage;

    const getErrorLabel = (errorMessage, showLabel) => {
        if (showLabel) {
            return (
                <>
                    {errorPrefix} {errorMessage}
                </>
            );
        }
        return errorMessage;
    };

    if (!showAnything) {
        return null;
    }

    return (
        <Box
            as="label"
            ref={ref}
            className={cx('glx-form__label', props.className)}
            __css={{
                fontSize: 'xs',
                ...styles.formLabel,
            }}
            {...ownProps}
        >
            <Tooltip
                placement="top-start"
                label={errorMessage}
                bg="red.600"
                isDisabled={!showFullErrorMessage}
            >
                <Flex alignItems="center">
                    <Box as="span">
                        {!showErrors && children}
                        {showIsRequired && getErrorLabel('is required', true)}
                        {showIsInvalidMaxLength &&
                            getErrorLabel('has exceeded the max length', true)}
                        {showFullErrorMessage && getErrorLabel(errorMessage, false)}
                        {showRequiredIndicator ? requiredIndicator : null}
                    </Box>
                    {helpText && (
                        <Box pl={1}>
                            <InformationIcon fontSize={'16px'}>{helpText}</InformationIcon>
                        </Box>
                    )}
                </Flex>
            </Tooltip>
        </Box>
    );
});

const ErrorIndicator = forwardRef(function ErrorIndicator(props, ref) {
    const { className, ...rest } = props;
    return (
        <Box marginLeft="auto" ref={ref} {...rest}>
            <Icon as={FaQuestionCircle} fontSize={'14px'} />
        </Box>
    );
});

/**
 * Used to show a "required" text or an asterisks (*) to indicate that
 * a field is required.
 */

const RequiredIndicator = forwardRef(function RequiredIndicator(props, ref) {
    const { children, className, ...rest } = props;
    const context = useFormControlContext();
    const styles = useStyles();

    if (!context?.isRequired) {
        return null;
    }

    const _className = cx('glx-form__required-indicator', className);

    return (
        <Box
            as="span"
            role="presentation"
            aria-hidden
            ref={ref}
            {...rest}
            sx={styles.requiredIndicator}
            className={_className}
        >
            {children || '*'}
        </Box>
    );
});

export { ErrorIndicator, FormControlLabel, RequiredIndicator };
