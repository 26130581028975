import { ariaAttr, callAllHandlers, omit } from '@chakra-ui/utils';
import { useFormControlContext } from './FormControl';

/**
 * React hook that provides the props that should be spread on to
 * input fields (`input`, `select`, `textarea`, etc.).
 *
 * It provides a convenient way to control a form fields, validation
 * and helper text.
 */
export function useFormControl(props) {
    const field = useFormControlContext();

    const cleanProps = omit(props, ['isInvalid', 'isDisabled', 'isReadOnly', 'isRequired']);

    const isReadOnly = props.readOnly || props.isReadOnly || field?.isReadOnly;

    return {
        ...cleanProps,
        id: props.id ?? field?.id,
        name: props.name ?? field?.name,
        disabled: props.disabled || props.isDisabled || field?.isDisabled,
        readOnly: isReadOnly,
        required: props.required || props.isRequired || field?.isRequired,
        'aria-invalid': ariaAttr(props.isInvalid || field?.isInvalid),
        'aria-required': ariaAttr(props.isRequired || field?.isRequired),
        'aria-readonly': ariaAttr(isReadOnly),
        onChange: callAllHandlers(props.onChange, field?.onChange), //call parent onChange last
        onFocus: callAllHandlers(field?.onFocus, props.onFocus),
        onBlur: callAllHandlers(field?.onBlur, props.onBlur),
        tabIndex: isReadOnly ? -1 : 0,
        maxLength: props.maxLength ?? field?.maxLength,
    };
}
