import React, { useCallback, useEffect, useState } from 'react';
import { ListItem, SimpleGrid, UnorderedList } from '@chakra-ui/react';
import { useToast } from '../../utils/toasts/useToast';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserInput } from '../../gql/generated/types';
import * as yup from 'yup';
import {
    GetUserQuery,
    useSaveUserProfileMutation,
    useGetUserQuery,
} from '../../gql/generated/users';
import { assign, pick } from 'lodash';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { EmailFormControl, SimpleFormControl } from '../../components/CompleteFormControls';
import { PhoneNumberFormControl } from '../../components/CompleteFormControls/PhoneNumberFormControl';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { validate } from 'email-validator';
import { createOnInvalid } from '../../utils/form/createOnInvalid';
import { useGetFeatureFlag } from '../../utils/config';
import { FeatureFlags } from '@app/lib';
import { getServerErrorToast } from '../../utils/toasts';

export interface useUserDetailsFormParams {
    username?: string | null;
    userData?: GetUserQuery['user'];
    isEditingDefault?: boolean;
    onSuccess?: () => void;
    onCancel?: () => void;
}

export const useUserDetailsForm = (options?: useUserDetailsFormParams) => {
    const {
        username,
        userData: givenUserData,
        onSuccess,
        onCancel: onCancelCallback,
        isEditingDefault = false,
    } = options ?? {};

    const toast = useToast();
    const [isEditing, setIsEditing] = useState(isEditingDefault);

    const [result, refetchDbUser] = useGetUserQuery({
        variables: { query: { username } },
        pause: !username || !!givenUserData,
    });
    const dbUser = givenUserData ?? result?.data?.user;

    const [submissionState, saveUserProfile] = useSaveUserProfileMutation();
    const isSubmitting = submissionState.fetching;

    //formResolver
    const form = useForm<Omit<UserInput, 'notifications' | 'audits'>>({
        resolver: yupResolver(
            yup.object().shape({
                id: yup.number().required(),
                firstName: yup
                    .string()
                    .nullable()
                    .trim()
                    .required('First name is a required field'),
                lastName: yup.string().nullable().trim().required('Last name is a required field'),
                position: yup.string().nullable().trim(),
                email: yup
                    .string()
                    .nullable()
                    .trim()
                    .required()
                    .test('validEmailCheck', 'Email format is invalid', function (value) {
                        return value ? validate(value) : false;
                    }),
                mobile: yup
                    .string()
                    .nullable()
                    .test(
                        'validPhoneNumberCheck',
                        'Mobile must be of valid format including country code (e.g. +61)',
                        function (value) {
                            if (value) {
                                return isValidPhoneNumber(value);
                            }
                            return true;
                        },
                    ),
                chatAlias: yup
                    .string()
                    .nullable()
                    .trim()
                    .max(50, 'Chat Nickname has a max length of 50 characters'),
            }),
        ),
    });

    //Reset the form with user info
    useEffect(() => {
        if (dbUser) {
            const formData = pick(dbUser, [
                'username',
                'company',
                'firstName',
                'lastName',
                'position',
                'email',
                'mobile',
                'id',
                'chatAlias',
            ]);
            form.reset(formData);
        }
    }, [dbUser, form]);

    //onFormSubmit
    const doSubmit = (data: any) => {
        if (!data.mobile) {
            data = assign({}, data, { mobile: null });
        }

        saveUserProfile({ user: data }).then((result) => {
            if (result.error) {
                toast(
                    getServerErrorToast(
                        result.error,
                        `There was a problem saving the user details.`,
                    ),
                );
            } else {
                toast({
                    title: 'Success',
                    status: 'success',
                    description: `User details updated.`,
                });
                setIsEditing(false);
                onSuccess?.();
            }
        });
    };

    const onCancel = () => {
        form.reset();
        setIsEditing(false);
        onCancelCallback?.();
    };

    const onSubmit = form.handleSubmit(
        doSubmit,
        createOnInvalid(toast, {
            id: 'id',
            firstName: 'first name',
            lastName: 'last name',
            email: 'email',
            mobile: 'mobile',
        }),
    );

    return {
        form,
        isEditing,
        setIsEditing,
        onSubmit,
        onCancel,
        isSubmitting,
    };
};

export interface UserDetailsFormProps {
    isEditing: boolean;
    form: UseFormReturn<Omit<UserInput, 'notifications' | 'audits'>>;
    hideReadOnly?: boolean;
}

export const UserDetailsForm = ({ isEditing, form, hideReadOnly }: UserDetailsFormProps) => {
    const chatAliasEnabled = useGetFeatureFlag(FeatureFlags.ChatAlias);
    return (
        <FormProvider {...form}>
            <SimpleGrid minChildWidth={280} spacing={4}>
                <SimpleFormControl display="none" type="hidden" name="id" />

                {!hideReadOnly && (
                    <>
                        <SimpleFormControl
                            label="Username"
                            name="username"
                            defaultValue=""
                            isReadOnly={true}
                        />
                        <SimpleFormControl label="Company" name="company.name" isReadOnly={true} />
                    </>
                )}
                <SimpleFormControl
                    label="First Name"
                    name="firstName"
                    defaultValue=""
                    isRequired={true}
                    isReadOnly={!isEditing}
                    maxLength={255}
                />
                <SimpleFormControl
                    label="Last Name"
                    name="lastName"
                    defaultValue=""
                    isRequired={true}
                    isReadOnly={!isEditing}
                    maxLength={255}
                />
                <SimpleFormControl
                    label="Position"
                    name="position"
                    defaultValue=""
                    isReadOnly={!isEditing}
                    maxLength={255}
                />
                <EmailFormControl
                    label="Email"
                    name="email"
                    defaultValue=""
                    isRequired={true}
                    isReadOnly={!isEditing}
                    maxLength={255}
                />
                <PhoneNumberFormControl
                    name="mobile"
                    label="Mobile"
                    placeholder="+61 000 000 000"
                    defaultValue=""
                    isReadOnly={!isEditing}
                    helpText="Entering a mobile number will allow notifications to be sent to this user via SMS."
                    maxLength={255}
                />
                {chatAliasEnabled && (
                    <SimpleFormControl
                        label="Chat Nickname"
                        name="chatAlias"
                        role="chatAlias"
                        defaultValue=""
                        isReadOnly={!isEditing}
                        placeholder="e.g. Trade Manager"
                        helpText={
                            <UnorderedList>
                                <ListItem>
                                    The Chat Nickname will display instead of first and last name
                                    when chatting with Counterparties
                                </ListItem>
                                <ListItem>
                                    Leaving this field empty will display first and last name when
                                    chatting with Counterparties
                                </ListItem>
                                <ListItem>
                                    Users at the same company will still be able to see first and
                                    last name in brackets
                                </ListItem>
                            </UnorderedList>
                        }
                    />
                )}
            </SimpleGrid>
        </FormProvider>
    );
};
