import React from 'react';
import { MdGroup, MdPerson, MdSettings, MdPeople } from 'react-icons/md';
import { useKeycloak } from '@react-keycloak/web';
import { generatePath } from 'react-router-dom';
import { Link } from '../../utils/pages/Link';
import { getEnvironmentVariable } from '../../utils/env';
import { AccountNavItem, AccountNavItemProps, AccountMenuItem } from '@app/components';
import { appPaths } from '../../utils/routeConfig';
import { useAbility } from '../../utils/auth';
import { RiFileList2Fill, RiLogoutBoxRFill } from 'react-icons/ri';
import { useUser } from '../../utils/stores/UserStore';

export type HeaderAccountProps = AccountNavItemProps;

export function HeaderAccount({ ...rest }: HeaderAccountProps): JSX.Element {
    const user = useUser();
    const { keycloak } = useKeycloak();
    const ability = useAbility();

    const [displayName, username] = React.useMemo(() => {
        const name = user?.name ?? user?.username ?? '';
        return [name, user?.username ?? undefined];
    }, [user]);

    const showLogout: boolean = getEnvironmentVariable('VITE_MENU_SHOW_LOGOUT') !== 'false';
    const showProfile: boolean = getEnvironmentVariable('VITE_MENU_SHOW_PROFILE') !== 'false';
    const showRoleSettings = ability.can('glxmanage', 'permission');
    const showActivityLog = ability.can('read', 'activitylog');
    const showSSOSettings = ability.can('glxmanage', 'auth');

    return (
        <AccountNavItem username={displayName} {...rest}>
            {showProfile && (
                <>
                    <AccountMenuItem
                        as={Link}
                        isNavLink
                        preload={false}
                        to={generatePath(appPaths.myCompanyUserProfile, { username })}
                        icon={<MdPerson />}
                    >
                        Profile
                    </AccountMenuItem>
                </>
            )}
            <AccountMenuItem
                as={Link}
                isNavLink
                preload={false}
                end
                to={generatePath(appPaths.myCompany)}
                icon={<MdGroup />}
            >
                My Company
            </AccountMenuItem>
            {showRoleSettings && (
                <AccountMenuItem
                    as={Link}
                    isNavLink
                    preload={false}
                    end
                    to={generatePath(appPaths.roles)}
                    icon={<MdSettings />}
                >
                    Role Settings
                </AccountMenuItem>
            )}
            {showActivityLog && (
                <AccountMenuItem
                    as={Link}
                    isNavLink
                    preload={false}
                    to={generatePath(appPaths.activityLog)}
                    end
                    icon={<RiFileList2Fill />}
                >
                    Activity Log
                </AccountMenuItem>
            )}
            {showSSOSettings && (
                <AccountMenuItem
                    as={Link}
                    isNavLink
                    preload={false}
                    to={generatePath(appPaths.ssoConfig)}
                    end
                    icon={<MdPeople />}
                >
                    SSO Settings
                </AccountMenuItem>
            )}
            {showLogout && (
                <AccountMenuItem
                    onClick={() => keycloak.logout({ redirectUri: window.location.origin + '/' })}
                    icon={<RiLogoutBoxRFill />}
                >
                    Logout
                </AccountMenuItem>
            )}
        </AccountNavItem>
    );
}
