import { createContext, useContext } from 'react';
import { Abilities, AppAbility, CreateForUser, User } from '@app/lib';
import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { getEnvironmentVariable } from '../env';

export const ability: AppAbility = new Ability<Abilities>();

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

export function updateAbility(user: User) {
    const newAbility = CreateForUser(user, getEnvironmentVariable('VITE_KEYCLOAK_CLIENT_ID'));

    ability.update(newAbility.rules as any);
}

export function useAbility(): AppAbility {
    return useContext(AbilityContext);
}
