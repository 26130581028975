import React, { useEffect } from 'react';
import { ConfirmButton, ModalRibbon } from '@app/components';
import { useDisclosure } from '@chakra-ui/hooks';
import {
    Icon,
    Modal,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    Text,
    ModalContent,
    ModalHeader,
} from '@chakra-ui/react';
import { rest } from 'lodash';
import { MdInfo } from 'react-icons/md';
import { useUser } from '../../utils/stores/UserStore';
import { UserDetailsForm, useUserDetailsForm } from '../../pages/Account/UserDetailsForm';

export const CompleteProfileModal = () => {
    const user = useUser();

    const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();

    useEffect(() => {
        if (user && (!user?.firstName || !user?.lastName)) {
            onOpenModal();
        }
    }, [onOpenModal, user]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onCloseModal}
            size="2xl"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
            {...rest}
        >
            <ModalOverlay />
            <ModalContent>
                {/* Won't render form unless modal is open, just for a minor optimization */}
                {isModalOpen && <CompleteProfileContent onSuccess={onCloseModal} />}
            </ModalContent>
        </Modal>
    );
};

interface CompleteProfileContentProps {
    onSuccess: () => void;
}

function CompleteProfileContent({ onSuccess }: CompleteProfileContentProps) {
    const user = useUser();

    const { form, onSubmit } = useUserDetailsForm({
        userData: user,
        isEditingDefault: true,
        onSuccess: onSuccess,
    });

    return (
        <>
            <ModalHeader fontSize="md" alignItems="center">
                <ModalRibbon>
                    <Icon as={MdInfo} />
                </ModalRibbon>
                Complete Profile
            </ModalHeader>
            <ModalBody>
                <Text marginBottom={2}>
                    Before you can access the system, please complete your profile. This is
                    essential for messaging and notification systems to function accurately.
                </Text>
                <UserDetailsForm isEditing={true} form={form} hideReadOnly={true} />
            </ModalBody>
            <ModalFooter>
                <ConfirmButton onClick={onSubmit} />
            </ModalFooter>
        </>
    );
}
