import React from 'react';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { PageContent } from '@app/components';
import { isBrowser } from 'react-device-detect';
import { useResizeDetector } from 'react-resize-detector';
import { useToken } from '@chakra-ui/system';
import { usePagePad } from '../../utils/theme/usePagePad';
import { AppBanners } from '../Banners/AppBanners';

export interface BaseLayoutProps {
    children: React.ReactNode;
}

function BaseLayout({ children }: BaseLayoutProps): JSX.Element {
    const pagePadSize = usePagePad();
    const [sidebarSize] = useToken('sizes', [28]);

    const showSidebar = !!isBrowser;
    const sidebarWidth = showSidebar ? sidebarSize : 0;

    // Detecting the height of any app banners that are showing
    const { height: bannerHeight, ref: bannerRef } = useResizeDetector({
        handleWidth: false,
        handleHeight: true,
    });

    // Detecting the header height and will pass that to the page content container
    const { height: headerHeight, ref: headerRef } = useResizeDetector({
        handleWidth: false,
        handleHeight: true,
    });

    const bannerHeightPx = `${bannerHeight ?? 0}px`;

    return (
        <>
            <AppBanners ref={bannerRef} />

            {showSidebar && <Sidebar width={sidebarWidth} top={bannerHeightPx} />}
            <Header
                id="page-header" // careful if changing, need to update references
                showHamburger={!showSidebar}
                showAccount={showSidebar}
                top={bannerHeightPx}
                paddingX={pagePadSize}
                paddingLeft={showSidebar ? `calc(${pagePadSize} + ${sidebarWidth})` : pagePadSize}
                ref={headerRef}
            ></Header>
            <PageContent
                topOffset={`${(headerHeight ?? 0) + (bannerHeight ?? 0)}px`}
                leftOffset={sidebarWidth}
                padding={pagePadSize}
            >
                {children}
            </PageContent>
        </>
    );
}

export default BaseLayout;
