import React, { useEffect } from 'react';
import store from 'store';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import keycloak from '../../utils/auth/keycloak';
import { useLocation, useNavigate } from 'react-router-dom';

const redirectUriVariable = 'redirectUri';

export type KeycloakManagerProps = {
    children: React.ReactNode;
};

// Stores the current path in local storage so we can refer back to it after login
const storePath = (pathname: string) => {
    store.set(redirectUriVariable, pathname);
};

const handleEvents = (event: any) => {
    if (event === 'onTokenExpired') {
        /**
         * Token has expired so user is about to be redirected to the keycloak login page.
         * Set the pathname in local storage so we can bring them back to the page they're on after login
         */
        storePath(window.location.pathname);
    }
};

export const KeycloakManager = ({ children }: KeycloakManagerProps) => {
    // Change the redirect Uri back to the base otherwise it will be invalid since we are no longer using a wildcard to valid redirects in keycloak
    keycloak.redirectUri = window.location.origin + '/';

    /**
     * Before we initialise keycloak we want to store the path the user is trying to access, so that we can bring them back to that path after the keycloak redirects.
     */
    useEffect(() => {
        /**
         * Assuming that if the hash includes a state parameter this has been added by keycloak when redirected back to our app.
         * In that case we won't bother storing the path so we don't override the previous path that was set prior to the keycloak redirect
         */
        if (!window.location.hash.includes('state')) {
            storePath(window.location.pathname);
        }
    }, []);

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{ onLoad: 'login-required' }}
            onEvent={handleEvents}
        >
            <KeycloakManagerInner />
            {children}
        </ReactKeycloakProvider>
    );
};

// Inner component so we can use the useKeycloak hook to get the state of the Keycloak provider
export const KeycloakManagerInner = () => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    const location = useLocation();

    /**
     * After initialising keycloak we want to keep the path stored up to date
     */
    useEffect(() => {
        /**
         * Assuming that if the hash includes a state parameter this has been added by keycloak when redirected back to our app.
         * In that case we won't bother storing the path so we don't override the previous path that was set prior to the keycloak redirect
         */
        if (!location.hash.includes('state')) {
            storePath(location.pathname);
        }
    }, [location.hash, location.pathname]);

    const currentPath = location.pathname;
    // Once user is declared as being authenticated we want to try take them back to the page they were attempting to access prior to being redirected to keycloak
    useEffect(() => {
        if (keycloak.authenticated) {
            /**
             * User is finally considered authenticated, so we can assume no more keycloak redirects are going to happen.
             * Check if we can find a stored path so we can redirect the user back to where they were trying to get to prior to the keycloak redirects
             */
            const redirectUri = store.get(redirectUriVariable);
            if (redirectUri && redirectUri != currentPath) {
                // We don't want to redirect if were already on the desired page.
                store.remove(redirectUriVariable);
                navigate(redirectUri);
            }
        }
    }, [keycloak.authenticated, currentPath, navigate]);

    return null;
};
