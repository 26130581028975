import { mapValues } from 'lodash';
import React from 'react';
import { PathMatch } from 'react-router-dom';
import { lazyPage, LazyRoute } from './pages/lazyPage';

export type AppRoutes =
    | 'home'
    | 'trades'
    | 'tradeDetails'
    | 'tradeTermsheet'
    | 'baseTermsheet'
    | 'counterparties'
    | 'counterpartiesCreate'
    | 'counterpartiesDetails'
    | 'counterpartiesUser'
    | 'counterpartiesUserProfile'
    | 'counterpartiesUserNotifications'
    | 'counterpartiesUserPermissions'
    | 'initiateNew'
    | 'initiateNewCopy'
    | 'initiateEdit'
    | 'templates'
    | 'templatesCreate'
    | 'templatesDetails'
    | 'myCompany'
    | 'myCompanyUser'
    | 'myCompanyUserProfile'
    | 'myCompanyUserNotifications'
    | 'myCompanyUserPermissions'
    | 'roles'
    | 'rolesCreate'
    | 'rolesDetails'
    | 'activityLog'
    | 'reviews'
    | 'reviewsDetails'
    | 'reviewsCreate'
    | 'ssoConfig';

export type RouteConfigComponentProps = {
    match: PathMatch<string> | null;
    routeConfig: RouteConfig;
    // General params that can be given to heading/breadcrumb components to be used as necessary
    params?: Record<string, any>;
};
export type RouteConfigComponent = (props: RouteConfigComponentProps) => React.ReactNode;

export type RouteConfig = {
    path: string;
    title?: string | RouteConfigComponent;
    /**
     * Intended rules for breadcrumbs:
     *  - If breadcrumb is undefined, use the page title
     *  - If breadcrumb is null, don't render the breadcrumb
     *  - Otherwise, breadcrumb can be a simple string or a function that returns a component
     */
    breadcrumb?: null | string | RouteConfigComponent;
    breadcrumbIcon?: React.ReactNode;
    parent?: AppRoutes;
    component: LazyRoute<any>;
};

export const Page = {
    Home: lazyPage(() => import('../pages/Home')),
    NotFound: lazyPage(() => import('../pages/NotFound')),
    CounterpartyList: lazyPage(() => import('../pages/Counterparties/CounterpartiesListPage')),
    CounterpartyCreatePage: lazyPage(
        () => import('../pages/Counterparties/CounterpartyCreatePage'),
    ),
    CompanyDetails: lazyPage(() => import('../pages/Counterparties/Details/CompanyDetailsPage')),
    TradeList: lazyPage(() => import('../pages/Trades/TradeListPage')),
    TradeDetails: lazyPage(() => import('../pages/Trades/TradeDetailsPage')),
    TemplateList: lazyPage(() => import('../pages/Templates/TemplateListPage')),
    TemplateDetailsPage: lazyPage(() => import('../pages/Templates/TemplateDetailsPage')),
    InitiateTradePage: lazyPage(() => import('../pages/Initiate/InitiateTradePage')),
    UserSettingsArea: lazyPage(() => import('../pages/Account/UserSettingsArea')),
    RoleListPage: lazyPage(() => import('../pages/Settings/Roles/RoleListPage')),
    RoleCreatePage: lazyPage(() => import('../pages/Settings/Roles/RoleCreatePage')),
    RoleDetailsPage: lazyPage(() => import('../pages/Settings/Roles/RoleDetailsPage')),
    ActivityLogPage: lazyPage(() => import('../pages/ActivityLog/ActivityLogPage')),
    ReviewsListPage: lazyPage(() => import('../pages/TermSheetReview/List/ReviewsListPage')),
    ReviewDetailsPage: lazyPage(() => import('../pages/TermSheetReview/Details/ReviewDetailsPage')),
    ReviewCreatePage: lazyPage(() => import('../pages/TermSheetReview/Create/ReviewCreatePage')),
    SSOConfigurationPage: lazyPage(() => import('../pages/Settings/SSO/SSOConfigurationPage')),
};

// Object providing the general structure of the app routes
// Used to act as a single source of truth for generating breadcrumbs, page titles, links, etc.
export const appRoutes: Record<AppRoutes, RouteConfig> = {
    home: {
        path: '/',
        title: 'Dashboard',
        breadcrumb: 'Dashboard',
        component: Page.Home,
    },
    // Trades
    trades: {
        path: '/trade', // can this be renamed to trades to follow the plural theme?
        title: 'Trades',
        component: Page.TradeList,
    },
    tradeDetails: {
        path: '/trade/details/:uid/*',
        // Currently the title is just a variable that is provided by the page component
        title: '{ref}',
        breadcrumb: 'Trade Details',
        parent: 'trades',
        component: Page.TradeDetails,
    },
    // Trade - Focused company activity (sub page route)
    tradeTermsheet: {
        path: '/trade/details/:uid/company/:companyId/activity/:activityId',
        breadcrumb: 'Term Sheet Details',
        parent: 'tradeDetails',
        component: Page.TradeDetails,
    },
    baseTermsheet: {
        path: '/trade/details/:uid/baseterms',
        breadcrumb: 'Base Terms',
        parent: 'tradeDetails',
        component: Page.TradeDetails,
    },
    // Counterparties
    counterparties: {
        path: '/counterparties',
        title: 'Counterparties',
        component: Page.CounterpartyList,
    },
    counterpartiesCreate: {
        path: '/counterparties/create',
        title: 'Create Counterparty',
        parent: 'counterparties',
        component: Page.CounterpartyCreatePage,
    },
    counterpartiesDetails: {
        path: '/counterparties/details/:id',
        title: 'Counterparty Details',
        parent: 'counterparties',
        component: Page.CompanyDetails,
    },
    counterpartiesUser: {
        path: '/counterparties/details/:id/user/:username/*',
        title: '{name}',
        breadcrumb: 'User Settings',
        parent: 'counterpartiesDetails',
        component: Page.UserSettingsArea,
    },
    counterpartiesUserProfile: {
        path: '/counterparties/details/:id/user/:username/profile',
        title: 'Profile',
        parent: 'counterpartiesUser',
        component: Page.UserSettingsArea,
    },
    counterpartiesUserNotifications: {
        path: '/counterparties/details/:id/user/:username/notifications',
        title: 'Notifications',
        parent: 'counterpartiesUser',
        component: Page.UserSettingsArea,
    },
    counterpartiesUserPermissions: {
        path: '/counterparties/details/:id/user/:username/permissions',
        title: 'Permissions',
        parent: 'counterpartiesUser',
        component: Page.UserSettingsArea,
    },

    // Initiate
    initiateNew: {
        path: '/trade/initiate',
        title: 'Initiate Trade',
        component: Page.InitiateTradePage,
    },
    initiateNewCopy: {
        path: '/trade/initiate/copy/:copyUid',
        title: 'Initiate Trade',
        component: Page.InitiateTradePage,
    },
    initiateEdit: {
        path: '/trade/initiate/edit/:uid',
        title: 'Initiate Trade',
        component: Page.InitiateTradePage,
    },

    // Templates
    templates: {
        path: '/templates',
        title: 'Templates',
        component: Page.TemplateList,
    },
    templatesCreate: {
        path: '/templates/new',
        title: 'Create Template',
        parent: 'templates',
        component: Page.TemplateDetailsPage,
    },
    templatesDetails: {
        path: '/templates/details/:id',
        title: 'Template Details',
        parent: 'templates',
        component: Page.TemplateDetailsPage,
    },

    // My company
    myCompany: {
        path: '/mycompany',
        title: 'My Company',
        component: Page.CompanyDetails,
    },
    myCompanyUser: {
        path: '/mycompany/user/:username/*',
        title: '{name}',
        breadcrumb: 'User Settings',
        parent: 'myCompany',
        component: Page.UserSettingsArea,
    },
    myCompanyUserProfile: {
        path: '/mycompany/user/:username/profile',
        title: 'Profile',
        parent: 'myCompanyUser',
        component: Page.UserSettingsArea,
    },
    myCompanyUserNotifications: {
        path: '/mycompany/user/:username/notifications',
        title: 'Notifications',
        parent: 'myCompanyUser',
        component: Page.UserSettingsArea,
    },
    myCompanyUserPermissions: {
        path: '/mycompany/user/:username/permissions',
        title: 'Permissions',
        parent: 'myCompanyUser',
        component: Page.UserSettingsArea,
    },

    // Roles
    roles: {
        path: '/settings/roles',
        title: 'Role Settings',
        component: Page.RoleListPage,
    },
    rolesCreate: {
        path: '/settings/roles/create',
        title: 'Create New Role',
        parent: 'roles',
        component: Page.RoleCreatePage,
    },
    rolesDetails: {
        path: '/settings/roles/:id',
        title: '{name}',
        breadcrumb: 'Role Details',
        parent: 'roles',
        component: Page.RoleDetailsPage,
    },

    // Activity Log
    activityLog: {
        path: '/activities',
        title: 'Activity Log',
        component: Page.ActivityLogPage,
    },

    // Termsheet Review
    reviews: {
        path: '/reviews',
        title: 'Term Sheet Reviews',
        component: Page.ReviewsListPage,
    },
    reviewsDetails: {
        path: '/reviews/details/:id',
        title: 'Review Details | {ref}',
        breadcrumb: 'Review Details',
        parent: 'reviews',
        component: Page.ReviewDetailsPage,
    },
    reviewsCreate: {
        path: '/reviews/create/:termsheetId',
        title: 'Create Review | {ref}',
        breadcrumb: 'Create Review',
        parent: 'reviews',
        component: Page.ReviewCreatePage,
    },
    // SSO Configuration
    ssoConfig: {
        path: '/settings/sso',
        title: 'Single Sign-On',
        component: Page.SSOConfigurationPage,
    },
};

export const appPaths: Record<AppRoutes, RouteConfig['path']> = mapValues(
    appRoutes,
    (route) => route.path,
);

export const pageMap: Record<
    AppRoutes,
    { path: RouteConfig['path']; component: RouteConfig['component'] }
> = mapValues(appRoutes, (route) => ({
    path: route.path,
    component: route.component,
}));

/**
 * <Routes> nested within other <Routes> need to use relative paths on their <Route> components
 * This will construct the relative paths of each route by subtracting the parent path from it's path
 */
export const appRelativePaths: Record<AppRoutes, string> = mapValues(appRoutes, (route) => {
    if (route.parent) {
        const parentPath = appRoutes[route.parent].path.replace('*', '');
        const leadingSlash = /^\//i;
        return route.path.replace(parentPath, '').replace(leadingSlash, '');
    }
    return route.path;
});
