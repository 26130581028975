import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';

const defaultOptions: UseToastOptions = {
    isClosable: true,
};

/**
 * Abstraction of chakra-ui's useToast with default options
 */
export const useToast = (options?: UseToastOptions | undefined) => {
    const opts = React.useMemo(
        () => ({
            ...defaultOptions,
            ...options,
        }),
        [options],
    );
    return useChakraToast(opts);
};
