import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import BaseLayout from '../BaseLayout';
import { pageMap, Page } from '../../utils/routeConfig';
import { PageSkeleton } from '../Skeletons/PageSkeleton';
import { withPermission } from '../Utils/RequirePermission';
import { useGetFeatureFlag } from '../../utils/config';
import { useUser } from '../../utils/stores/UserStore';
import { FeatureFlags } from '@app/lib';

export const Pages = () => {
    const termSheetReviewEnabled = useGetFeatureFlag(FeatureFlags.ReviewTermSheet);
    const user = useUser();
    const isOwningCompany = !!user?.company?.owner;

    return (
        <BaseLayout>
            <Suspense fallback={<PageSkeleton />}>
                <Routes>
                    {/* Dashboard */}
                    <Route path={pageMap.home.path} element={<pageMap.home.component />} />

                    {/* Counterparty */}
                    <Route
                        path={pageMap.counterparties.path}
                        element={withPermission(<pageMap.counterparties.component />, {
                            permissions: [['read', 'company']],
                            check: isOwningCompany,
                        })}
                    />
                    <Route
                        path={pageMap.counterpartiesCreate.path}
                        element={withPermission(<pageMap.counterpartiesCreate.component />, [
                            ['glxmanage', 'company'],
                        ])}
                    />
                    <Route
                        path={pageMap.counterpartiesDetails.path}
                        element={withPermission(
                            <pageMap.counterpartiesDetails.component pageType="counterparty" />,
                            {
                                permissions: [['read', 'company']],
                                check: isOwningCompany,
                            },
                        )}
                    />
                    <Route
                        path={pageMap.counterpartiesUser.path}
                        element={withPermission(
                            <pageMap.counterpartiesUser.component routeParent="counterpartiesDetails" />,
                            {
                                permissions: [['read', 'companyuser']],
                                check: isOwningCompany,
                            },
                        )}
                    />

                    {/* Templates */}
                    <Route
                        path={pageMap.templates.path}
                        element={withPermission(<pageMap.templates.component />, [
                            ['read', 'template'],
                        ])}
                    />
                    <Route
                        path={pageMap.templatesCreate.path}
                        element={withPermission(<pageMap.templatesCreate.component />, [
                            ['glxmanage', 'template'],
                        ])}
                    />
                    <Route
                        path={pageMap.templatesDetails.path}
                        element={withPermission(<pageMap.templatesDetails.component />, [
                            ['read', 'template'],
                        ])}
                    />

                    {/* Trade Details */}
                    <Route
                        path={pageMap.trades.path}
                        element={withPermission(<pageMap.trades.component />, [['read', 'trade']])}
                    />
                    <Route
                        path={pageMap.tradeDetails.path}
                        element={withPermission(<pageMap.tradeDetails.component />, [
                            ['read', 'trade'],
                        ])}
                    />

                    {/* Trade Initiation */}
                    <Route
                        path={pageMap.initiateNew.path}
                        element={withPermission(<pageMap.initiateNew.component />, [
                            ['glxmanage', 'trade'],
                        ])}
                    />
                    <Route
                        path={pageMap.initiateEdit.path}
                        element={withPermission(<pageMap.initiateEdit.component />, [
                            ['glxmanage', 'trade'],
                        ])}
                    />
                    <Route
                        path={pageMap.initiateNewCopy.path}
                        element={withPermission(<pageMap.initiateNewCopy.component />, [
                            ['glxmanage', 'trade'],
                        ])}
                    />

                    {/* My Company */}
                    <Route
                        path={pageMap.myCompany.path}
                        element={withPermission(
                            <pageMap.myCompany.component pageType="myCompany" />,
                            [['read', 'mycompany']],
                        )}
                    />
                    <Route
                        path={pageMap.myCompanyUser.path}
                        element={withPermission(
                            <pageMap.myCompanyUser.component routeParent="myCompany" />,
                            [['read', 'mycompanyuser']],
                        )}
                    />

                    {/* Roles - Note: not rendering links to role pages unless they can manage them hence using "glxmanage" instead of "read" even for list/details page */}
                    <Route
                        path={pageMap.roles.path}
                        element={withPermission(<pageMap.roles.component />, [
                            ['glxmanage', 'permission'],
                        ])}
                    />
                    <Route
                        path={pageMap.rolesCreate.path}
                        element={withPermission(<pageMap.rolesCreate.component />, [
                            ['glxmanage', 'permission'],
                        ])}
                    />
                    <Route
                        path={pageMap.rolesDetails.path}
                        element={withPermission(<pageMap.rolesDetails.component />, [
                            ['glxmanage', 'permission'],
                        ])}
                    />

                    {/* Activity Log */}
                    <Route
                        path={pageMap.activityLog.path}
                        element={withPermission(<pageMap.activityLog.component />, [
                            ['read', 'activitylog'],
                        ])}
                    />

                    {/* Term Sheet Review */}
                    {termSheetReviewEnabled && (
                        <Route
                            path={pageMap.reviews.path}
                            element={withPermission(<pageMap.reviews.component />, [
                                ['read', 'termsheetreview'],
                            ])}
                        />
                    )}
                    {termSheetReviewEnabled && (
                        <Route
                            path={pageMap.reviewsDetails.path}
                            element={withPermission(<pageMap.reviewsDetails.component />, [
                                ['read', 'termsheetreview'],
                            ])}
                        />
                    )}
                    {termSheetReviewEnabled && (
                        <Route
                            path={pageMap.reviewsCreate.path}
                            element={withPermission(<pageMap.reviewsCreate.component />, [
                                ['create', 'termsheetreview'],
                            ])}
                        />
                    )}

                    {/* SSO Configuration */}
                    <Route
                        path={pageMap.ssoConfig.path}
                        element={withPermission(<pageMap.ssoConfig.component />, [
                            ['glxmanage', 'auth'],
                        ])}
                    />

                    {/* Not Found Fallback */}
                    <Route path="*" element={<Page.NotFound />} />
                </Routes>
            </Suspense>
        </BaseLayout>
    );
};
